import React, { useState } from 'react'
import { MenuOutlined, PhoneOutlined } from '@ant-design/icons'
import { Button, Col, Drawer, Layout, Menu, Row, Typography } from 'antd'

const { Link, Title } = Typography

const Header = () => {
  const [showDrawer, setShowDrawer] = useState(false)
  const [selectedMenu, setSelectedMenu] = useState()

  const showMenuDrawer = () => {
    setShowDrawer(true)
  }
  const onClose = () => {
    setShowDrawer(false)
  }

  const handleMenuClick = e => {
    setSelectedMenu(e.key)
  }

  return (
    <div className="accessibility">
      {/* <Layout.Header className="alert-nav">
        <Row>
          <Col className="margin-auto mi-header">
            <img alt="logo" className="logo" src="https://www.cure.com/images/mi.png" />
            <Title className="alert-title" level={3}>
              GREAT LAKES, GREAT INSURANCE - NOW IN MICHIGAN!
            </Title>
            <img alt="alert-nav-img" className="alert-nav-img" src="https://www.cure.com/images/mi_underline.png" />
          </Col>
        </Row>
      </Layout.Header> */}
      <Layout.Header className="menu-nav">
        <Row>
          <Col lg={7} md={6} sm={7} xl={8} xs={10}>
            <div className="logo-holder">
              <a href="/">
                <img alt="menu-logo" src="https://assets.cure.com/img/main-site/cure-logo.png" />
              </a>
            </div>
          </Col>
          <Col lg={8} md={10} sm={6} xl={7} xs={11} style={{ justifyContent: 'center' }}/>
          <Col lg={9} md={8} sm={10} xl={9} xs={2}>
            <div className="expanol-link-container">
              <Link
                className="rsa"
                href="tel:+18005352873"
                style={{
                  marginRight: 20,
                  color: '#fff',
                  verticalAlign: 'middle'
                }}
              >
                <PhoneOutlined className="phone" />
                <span className='contactNumber'>800-535-CURE (2873)</span>
              </Link>
              <Button className="menu-icon" icon={<MenuOutlined />} onClick={showMenuDrawer} size="large" />
            </div>
          </Col>
          <Drawer onClose={onClose} placement="right" visible={showDrawer}>
            <Menu className="drawer-menu" mode="inline" onClick={handleMenuClick} selectedKeys={[selectedMenu]}>
              <Menu.Item key="6" style={{ paddingTop: 3 }}>
                <Link href="/my-account" rel="noopener noreferrer">
                  MY ACCOUNT
                </Link>
              </Menu.Item>
              <Menu.Item key="4" style={{ paddingTop: 3 }}>
                <Link href="/roadside" rel="noopener noreferrer">
                  ROADSIDE ASSISTANCE
                </Link>
              </Menu.Item>
              <Menu.Item key="4" style={{ paddingTop: 3 }}>
                <Link href="https://www.cure.com/claims.aspx" rel="noopener noreferrer" target="_blank">
                  CLAIMS
                </Link>
              </Menu.Item>
              <Menu.Item key="4" style={{ paddingTop: 3 }}>
                <Link href="#">CONTACT</Link>
              </Menu.Item>
              <Menu.Item key="4" style={{ paddingTop: 3 }}>
                <Link href="/">HOME</Link>
              </Menu.Item>
            </Menu>
          </Drawer>
        </Row>
      </Layout.Header>
    </div>
  )
}

export default Header
