import { useEffect, useState } from "react"
import { initiateAgentlessConversation } from "../../services/Api"
import Cookies from 'js-cookie'
import { getTestingEnvToken } from "../../utils/domainUtil"

export function Script(handleShowMessagingTool) {
  const [phone, setPhone] = useState('')
  const [textSent, setTextSent] = useState(false)
  const [mobileApp, setMobileApp] = useState(Cookies.get('CMA', { domain: '.cure.com' }))
  // const [mobileApp, setMobileApp] = useState(true)
  const [loading, setLoading] = useState(false)
  const sendText = async () => {
    const formattedPhone = formatPhoneNumber(phone)
    initiateAgentlessConversation(formattedPhone)
    setTextSent(true)
    setTimeout(() => {
        //after 10 minutes, allow the customer to send another message
        //reset the component to default
        setTextSent(false)
        setPhone(false)
    }, 600000)
}

  const webMessaging = () => {
    handleShowMessagingTool()
  }

  const formatPhoneNumber = phoneNumber => '+1' + phoneNumber.replace(/\D/g, '')

  useEffect(() => {
    console.log(phone)
  }, [phone])
  
  return {
    sendText,
    webMessaging,
    phone,
    setPhone,
    textSent,
    setTextSent,
    mobileApp,
    setMobileApp,
    loading,
    setLoading
  }
}
