import React from 'react'
//import { Typography } from 'antd'
import PropTypes from 'prop-types'

import ButtonStyle from '../../styles/ButtonsRow.module.css'

//const { Title } = Typography

const ButtonsRow = ({ title, children }) => {
  return (
    <div className={`${ButtonStyle["ButtonsRow"]}`}>
      <h5 className={`${ButtonStyle["customer-links"]}`}>
        {title}
      </h5>
      <div className={`${ButtonStyle["button-grid"]}`}>{children}</div>
    </div>
  )
}

ButtonsRow.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string
}

export default ButtonsRow
