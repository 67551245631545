import React from 'react'
import PropTypes from 'prop-types'
import Head from 'next/head'

const CANONICAL_BASE_URL = 'https://www.cure.com'

const Seo = ({ description, title, url }) => {
  return (
    <Head>
      <title>{title}</title>
      <meta content={description} name="description" />
      <meta content="width=device-width, initial-scale=1.0" name="viewport" />
      <meta name="google-site-verification" content="z6rSevZR1gRqH8wZyEipBVMIypa1qd5JpxnhJecZXRw" />
      <link data-react-helmet="true" href={`${CANONICAL_BASE_URL}${url}`} rel="canonical" />
    </Head>
  )
}

export default Seo

Seo.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string
}

Seo.defaultProps = {
  description: null,
  title: null,
  url: ''
}
