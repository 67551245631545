import React, { useRef, useEffect, useState } from 'react'
import { Button, Col, Form, Input, Row, Typography } from 'antd'
import { ButtonCell, ButtonsRow } from './components'
import { useWindowSize, getDomain } from '../utils'
import { useRouter } from 'next/router'
import { useApiData } from '../context/apiContext'

import QuickStyle from '../../styles/QuickService.module.css'

const { Link, Title, Text } = Typography

const QuickService = (props) => {
  const router = useRouter()
  const zipCodeInput = useRef(null)
  const [zipCode, setZipCode] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const windowSize = useWindowSize()
  const isMobile = windowSize.width > 1024
  const [websiteMaintenanceMessage, setWebsiteMaintenanceMessage] = useState(null)
  const [websiteMaintenanceStatus, setWebsiteMaintenanceStatus] = useState(false)
  const { apiData1, apiData2 } = useApiData();

  useEffect(() => {
    if (zipCodeInput.current && windowSize.width && isMobile) {
      zipCodeInput.current.focus()
    }
  }, [zipCodeInput, windowSize, isMobile])

  useEffect(() => {
    if (apiData1 && apiData2) {
      function fetchWebsiteMaintainence() {
        var websiteStatusResponse = apiData1
        var websiteMessageResponse = apiData2

        if (websiteStatusResponse && websiteStatusResponse?.data) {
          const status = websiteStatusResponse?.data
          if (status.Result == "Active" && status?.Pages.includes("GetQuote")) {
            setWebsiteMaintenanceStatus(true)
          }
        }
        if (websiteMessageResponse && websiteMessageResponse?.data) {
          setWebsiteMaintenanceMessage(websiteMessageResponse?.data?.Result)
        }
      }
      fetchWebsiteMaintainence()
    }
  }, [apiData1, apiData2]);

  const handleZipCodeChange = event => {
    const value = event.target.value.replace(/\D/g, '')
    setZipCode(value)
  }

  const handleClick = event => {
    setErrorMessage('')
    if (zipCode && zipCode.length > 0 && zipCode.length === 5) {
      const newWindow = window.open('https://' + getDomain('quote') + `/default.aspx?zip=${zipCode}`, '_self')
      if (newWindow) newWindow.opener = null
    } else {
      setErrorMessage('Please enter a valid 5 digit ZIP Code.')
    }
  }
  const handleRetrieveClick = event => {
    setErrorMessage('')
    window.open('https://' + getDomain('quote') + '/retrieve-saved-quote.aspx', '_self')

  }
  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      setErrorMessage('')
      if (zipCode && zipCode.length > 0 && zipCode.length === 5) {
        const newWindow = window.open('https://' + getDomain('quote') + `/default.aspx?zip=${zipCode}`, '_self')
        if (newWindow) newWindow.opener = null
      } else {
        setErrorMessage('Please enter a valid 5 digit ZIP Code.')
      }
    }
  }

  const handleExpressPolicyChanges = () => {
    const newWindow = window.open('/my-account', '_self', 'noopener, noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const handlePaymentClick = event => {
    props.setLoading(true)
    setErrorMessage('')
    window.open('https://' + getDomain('payments'), '_self')
  }

  const handlePIPDocumentUpload = () => {
    const newWindow = window.open('/g/submit-documents', '_self', 'noopener, noreferrer')
    if (newWindow) newWindow.opener = null
  }

  return (
    <Row className={`${QuickStyle["fixed"]}`}>
      <Col className={`${QuickStyle["QuickBox"]}`} lg={12} md={15} sm={18} xl={10} xs={22}>
        <div className={`${QuickStyle["GetAQuote"]}`}>
          <Row>
            <Col className={`${QuickStyle["header-title"]} text-center`}>
              <Title className={`${QuickStyle["title"]} ${QuickStyle["font-size-52"]}`} level={2}>
                Drive Well, Save More
              </Title>
            </Col>
          </Row>
          <Row>
            <Col className={`${QuickStyle["margin-auto"]}`}>
              <Text className={`${QuickStyle["sub-header-title"]} ${QuickStyle["line-height-1"]} ${QuickStyle["margin-bottom-12"]} text-center`} 
                style={{fontSize: '24px'}}>
                Quote <div className={`${QuickStyle["period"]}`} /> Apply <div className={`${QuickStyle["period"]}`} /> Save
              </Text>
              <Text className={`${QuickStyle["width-300"]} ${QuickStyle["margin-bottom-18"]} sub-title text-center`}>
                {' '}
                Three easy steps for next day coverage.
              </Text>
            </Col>
          </Row>
          <Row>
            <Col className={`${QuickStyle["margin-auto"]}`}>
              <Form className={`${QuickStyle["form-inline"]}`} layout="inline">
                <Form.Item>
                  <Input
                    ref={zipCodeInput}
                    maxLength={5}
                    onChange={handleZipCodeChange}
                    onKeyDown={handleKeyDown}
                    placeholder="ZIP CODE"
                    value={zipCode}
                    type={"tel"}
                    disabled={websiteMaintenanceStatus}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    className={`${QuickStyle["start-quote"]} ${QuickStyle["qs-start-quote"]}`}
                    onClick={handleClick}
                    rel="noopener noreferrer"
                    style={{ background: '#ff8000', border: 'none' }}
                    disabled={websiteMaintenanceStatus}
                  >
                    START QUOTE
                  </Button>
                </Form.Item>
              </Form>
              {errorMessage && <div className={`${QuickStyle["error-message"]}`}>{errorMessage}</div>}
              <Row style={{ width: '100%' }}>
                <Col className={`${QuickStyle["past-quote-container"]}`}>
                  <Link
                    className={`${QuickStyle["past-quote"]}`}
                    onClick={handleRetrieveClick}
                    target="_self"
                  >
                    Retrieve Saved Quote
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        <div className={`${QuickStyle["ExpressButtonsRow"]}`}>
          <Title className={`${QuickStyle["customer-links"]}`} level={5}>EXPRESS POLICY CHANGES</Title>
          <div className={`${QuickStyle["button-grid"]}`}>
            <Col className={`${QuickStyle["header-title"]} text-center`}>
              <Title className={`${QuickStyle["express-title"]}`} level={4}>Need a quick policy change?</Title>
              <Row>
                <Col className={`${QuickStyle["margin-auto"]}`}>
                  <Text className={`${QuickStyle["sub-header-title"]} ${QuickStyle["line-height-1"]} text-center`}>Vehicles <div className={`${QuickStyle["period"]}`} /> Drivers <div className={`${QuickStyle["period"]}`} /> Address</Text>
                </Col>
              </Row>
            </Col>
            <Col className={`${QuickStyle["header-title"]} ${QuickStyle["express-button"]}`}>
              <Button onClick={handleExpressPolicyChanges}>GO!</Button>
            </Col>
          </div>
        </div>

        <div className={`${QuickStyle["ExpressButtonsRow"]}`}>
          <Title className={`${QuickStyle["customer-links"]}`} level={5}>
            SUBMIT REQUESTED FILES
          </Title>
          <div className={`${QuickStyle["button-grid"]}`}>
            <Col className={`${QuickStyle["header-title"]} text-center`}>
              <Title className={`${QuickStyle["express-title"]}`} level={4}>
                Policy document center
              </Title>
              <Row>
                <Col className={`${QuickStyle["margin-auto"]}`}>
                  <Text className={`${QuickStyle["sub-header-title"]} ${QuickStyle["line-height-1"]} text-center`}>Michigan <div className={`${QuickStyle["period"]}`} /> New Jersey <div className={`${QuickStyle["period"]}`} /> Pennsylvania</Text>
                </Col>
              </Row>
            </Col>
            <Col className={`${QuickStyle["header-title"]} ${QuickStyle["express-button"]}`}>
              <Button onClick={handlePIPDocumentUpload}>UPLOAD NOW</Button>
            </Col>
          </div>
        </div>

        <ButtonsRow title="CUSTOMER LINKS">
          <ButtonCell onClick={() => props.setLoading(true)} link="/my-account" logoType="get_id_card" mainText="GET" subText="ID CARD" />
          <ButtonCell
            onClick={(e) => handlePaymentClick(e)}
            logoType="make_payment"
            mainText="MAKE"
            subText="PAYMENT"
          />
          {/* <ButtonCell link={getDomainQuote} onClick={() => setErrorMessage('')} logoType='make_payment' mainText="MAKE" subText="PAYMENT" /> */}
          <ButtonCell onClick={() => { props.setLoading(true); router.push("/claims/fnol-form") }} link="/claims/fnol-form" logoType="file_claim" mainText="REPORT" selfOpen={true} subText="CLAIM" />
          <ButtonCell onClick={() => props.setLoading(true)} link="/my-account" logoType="manage_policy" mainText="MANAGE" selfOpen={true} subText="POLICY" />
        </ButtonsRow>
      </Col>
    </Row>
  )
}

export default QuickService
