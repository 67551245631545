import React, { useEffect, useState } from 'react'
import { CheckCircleFilled, LoadingOutlined } from '@ant-design/icons'
import { Checkbox, Row, Select, Card, Form, Input, Spin, Col, Button, Space, Typography, Result } from 'antd'
import InputMask from 'react-input-mask'
import { EmptyHeaderHero } from './components'
import { checkGWAccount, getSecurityQuestions, registerAccount, verifyLogin, isUsernameRegistered, getZip } from '../services'
import { getDomain, getHostName } from '../utils'
import { useRouter } from 'next/router'

import UpdateStyle from '../../styles/Update.module.css'

const { Option } = Select
const { Text } = Typography

const antIcon = <LoadingOutlined spin style={{ fontSize: 24 }} />

const securityQuestions = [
    {
        Code: 'MAIDEN',
        Text: "What is your mother's maiden name?"
    },
    {
        Code: 'SINGERBAND',
        Text: 'Who is your favorite singer or band?'
    },
    {
        Code: 'MOVIE',
        Text: 'What is your favorite movie?'
    },
    {
        Code: 'FIRSTCARCOLOR',
        Text: 'What was the color of your first car?'
    },
    {
        Code: 'STREETNAME',
        Text: 'What is the name of the street you grew up on?'
    }
]

const Update = () => {
    const [filteredQuestions, setFilteredQuestions] = useState([])
    const [questionVals, setQuestionVals] = useState([null, null, null])
    const [message, setMessage] = useState(null)
    const [user, setUser] = useState({
        EmailAddress: '',
        FirstName: '',
        LastName: '',
        DOB: '',
        Password: '',
        RetypePassword: '',
        SecurityQuestion1: '',
        SecurityQuestion2: '',
        SecurityQuestion3: '',
        SecurityAnswer1: '',
        SecurityAnswer2: '',
        SecurityAnswer3: '',
        Env: getHostName()
    })
    const [isAgreed, setIsAgreed] = useState(false)
    const [showAgreement, setShowAgreement] = useState(false)
    const [showDuplicateEmailPage, setShowDuplicateEmailPage] = useState(false);
    const [selectedState, setSelectedState] = useState(null)
    const [showSpinner, setShowSpinner] = useState(false)
    const [passwordValidationError, setPasswordValidationError] = useState(false)
    const [passwordMatchValidationError, setPasswordMatchValidationError] = useState(false)
    const [duplicateEmailValidationError, setDuplicateEmailValidationError] = useState(false)
    const [duplicateUsernameValidationError, setDuplicateUsernameValidationError] = useState(false)
    const [duplicateEmailMatchValidationError, setDuplicateEmailMatchValidationError] = useState(false)
    const [duplicateUsernameMatchValidationError, setDuplicateUsernameMatchValidationError] = useState(false)
    const [usernameVerified, setUsernameVerified] = useState(false);
    const [usernameNotFound, setUsernameNotFound] = useState(false);
    const [alreadyConverted, setAlreadyConverted] = useState(false);
    const [policyholderInformationError, setPolicyholderInformationError] = useState(false)
    const [userGW, setUserGW] = useState({})
    const [duplicateEmailNewEmail, setDuplicateEmailNewEmail] = useState("")
    const [duplicateEmailConfirmEmail, setDuplicateEmailConfirmEmail] = useState("")
    const [duplicateUsernameNewUsername, setDuplicateUsernameNewUsername] = useState("")
    const [duplicateUsernameConfirmUsername, setDuplicateUsernameConfirmUsername] = useState("")
    const [duplicateEmailErrorMessage, setDuplicateEmailErrorMessage] = useState(false)
    const [duplicateUsernameErrorMessage, setDuplicateUsernameErrorMessage] = useState(false)
    const [registrationErrorMessage, setRegistrationErrorMessage] = useState("")

    const [isDuplicateUsername, setIsDuplicateUsername] = useState(false)

    const router = useRouter();


    useEffect(() => {
        const search = window.location.search
        const params = new URLSearchParams(search)
        setSelectedState(params.get('state'))
    }, [])

    useEffect(() => {
        if (!!router.query.verifiedUser) {
            setUsernameVerified(true);
            updateTextFields(router.query.verifiedUser, "EmailAddress")
            handleValidateUsername();
            const userGW = JSON.parse(localStorage.getItem('gwAccountUser') || null)
            if(userGW){
                setUser(userGW)
                localStorage.removeItem('gwAccountUser')
            }
        }

    }, [router.query])

    useEffect(() => {
        async function fetchSecurityQuestions() {
            const response = await getSecurityQuestions()
            const data = response.data.filter(obj => obj.Code !== '')
            setFilteredQuestions(data)
        }
        if (filteredQuestions && filteredQuestions.length === 0) {
            fetchSecurityQuestions()
        }
    }, [filteredQuestions])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [showAgreement])

    const handleQuestionValChange = (option, index) => {
        const newQuestionVals = [...questionVals]
        newQuestionVals[index] = option
        setQuestionVals(newQuestionVals)
    }

    const getAvailableOptions = () => {
        const availableOptionsLeft = [...filteredQuestions]
        return availableOptionsLeft.filter(questionOption => {
            return questionVals.indexOf(questionOption.Code) === -1
        })
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    const handleAgree = async () => {
        const clonedUser = { ...user }
        const response = await verifyLogin(duplicateEmailNewEmail === "" ? clonedUser.EmailAddress : duplicateEmailNewEmail, "", "login")
        if (response && response.data) {
            const { Result, Tkn } = response.data
            if (Result === "Not Found" && !!duplicateEmailNewEmail) {
                handleFinish()
            } else if (Result === "Non Converted GW User" || Result === "Policy User and Non Converted GW User") {
                //a non-converted policy account exists for the email; update the account to active policy account
                handleFinish()
            }
        }
    }

    const handleDuplicateEmailFinish = async () => {
        const response = await verifyLogin(duplicateEmailNewEmail, "", "login")
        if (response && response.data) {
            const { Result, Tkn } = response.data
            if (Result === "Non Converted GW User" || Result === "Not Found") {
                //no active policy account exists for the new email but another non-converted account has that email: still allow registration
                setShowAgreement(true)
                setShowDuplicateEmailPage(false)
                setIsDuplicateUsername(false)
            } else if (Result === "Policy User and Non Converted GW User" || Result === "Success" || Result === "Incorrect Password") {
                //an active policy account exists for that email: show duplicate email error message
                setDuplicateEmailErrorMessage(true)
            }
        }
    }

    const handleDuplicateUsernameFinish = async () => {
        const checkgwaccount_resp = await checkGWAccount({ FirstName: user.FirstName.replace(/\s/g,''), LastName: user.LastName.replace(/\s/g,''), DOB: user.DOB, EmailAddress: user.EmailAddress })
        if (checkgwaccount_resp && checkgwaccount_resp.data) {
            const { Result, Account } = checkgwaccount_resp.data
            if (Result === "Success") {
                const isusernameregistered_resp = await isUsernameRegistered({ PolicyNumber: null, ZipCode: null, Username: duplicateUsernameNewUsername })
                if (isusernameregistered_resp && isusernameregistered_resp.data) {
                    if (isusernameregistered_resp.data.Result === "Registered") {
                        setDuplicateUsernameErrorMessage(true)
                    }
                    else {
                        setDuplicateUsernameErrorMessage(false)
                        setIsDuplicateUsername(false)
                        setShowDuplicateEmailPage(false)
                        setShowAgreement(true)
                        // setIsDuplicateUsername(false)
                    }
                }
            }
        }
    }

    const handleFinish = async () => {
        setRegistrationErrorMessage("")
        setShowSpinner(true)
        const clonedUser = { ...user }
        const response = await checkGWAccount({ FirstName: clonedUser.FirstName.replace(/\s/g,''), LastName: clonedUser.LastName.replace(/\s/g,''), DOB: clonedUser.DOB, EmailAddress: clonedUser.EmailAddress })
        if (response && response.data) {
            const getzip_res = await getZip({ PolicyNumber: response.data.Account.Policy_Number })
            if (getzip_res && getzip_res.data && getzip_res.data !== response.data.Account.Zip) {
                response.data.Account.Zip = getzip_res.data.substring(0, 5)
            }
            const isusernameregistered_resp = await isUsernameRegistered({ PolicyNumber: null, ZipCode: null, Username: response.data.Account.GWUserID })
            if (isusernameregistered_resp && isusernameregistered_resp.data) {
                if (isusernameregistered_resp.data.Result === "Registered") {
                    if (duplicateEmailNewEmail !== "") {
                        //duplicate email address, duplicate username
                        //user duplicateEmailNewEmail for both EmailAddress and Username
                        const registerAccount_response = await registerAccount({ ...user, FirstName: response.data.Account.FirstName, LastName: response.data.Account.LastName, EmailAddress: duplicateEmailNewEmail, PolicyNumber: response.data.Account.Policy_Number, Zip: response.data.Account.Zip, Username: duplicateEmailNewEmail, IsGWUpdate: true });
                        if (registerAccount_response && registerAccount_response.data) {
                            if (registerAccount_response.data.Result === "Account Migrated Successfully" || registerAccount_response.data.Result === "Account Created") {
                                handleLogin(duplicateEmailNewEmail, user.Password)
                            } else {
                                setRegistrationErrorMessage("Error: " + registerAccount_response.data.Result)
                            }
                        } else {
                            setRegistrationErrorMessage("Error: an error occured while registering the account.")
                        }
                    } else {
                        //nonduplicate email, duplicate username
                        //use original emailaddress for EmailAddress, use duplicateUsernameNewUsername for Username
                        const registerAccount_response = await registerAccount({ ...user, FirstName: response.data.Account.FirstName, LastName: response.data.Account.LastName, EmailAddress: response.data.Account.EmailAddress, PolicyNumber: response.data.Account.Policy_Number, Zip: response.data.Account.Zip, Username: duplicateUsernameNewUsername, IsGWUpdate: true })
                        if (registerAccount_response && registerAccount_response.data) {
                            if (registerAccount_response.data.Result === "Account Migrated Successfully" || registerAccount_response.data.Result === "Account Created") {
                                handleLogin(response.data.Account.EmailAddress, user.Password)
                            } else {
                                setRegistrationErrorMessage("Error: " + registerAccount_response.data.Result)
                            }
                        } else {
                            setRegistrationErrorMessage("Error: an error occured while registering the account.")
                        }
                    }
                }
                else {
                    if (duplicateEmailNewEmail !== "") {
                        //duplicate email address, no duplicate username
                        //Username = Account.GWUserID, EmailAddress=duplicateEmailNewEmail
                        const registerAccount_response = await registerAccount({ ...user, FirstName: response.data.Account.FirstName, LastName: response.data.Account.LastName, EmailAddress: duplicateEmailNewEmail, PolicyNumber: response.data.Account.Policy_Number, Zip: response.data.Account.Zip, Username: response.data.Account.GWUserID, IsGWUpdate: true });
                        if (registerAccount_response && registerAccount_response.data) {
                            if (registerAccount_response.data.Result === "Account Migrated Successfully" || registerAccount_response.data.Result === "Account Created") {
                                handleLogin(duplicateEmailNewEmail, user.Password)
                            } else {
                                setRegistrationErrorMessage("Error: " + registerAccount_response.data.Result)
                            }
                        } else {
                            setRegistrationErrorMessage("Error: an error occured while registering the account.")
                        }

                    } else {
                        //no duplicate email, no duplicate username
                        //use Account.EmailAddress and Account.GWUserID for EmailAddress and Username
                        const registerAccount_response = await registerAccount({ ...user, FirstName: response.data.Account.FirstName, LastName: response.data.Account.LastName, EmailAddress: response.data.Account.EmailAddress, PolicyNumber: response.data.Account.Policy_Number, Zip: response.data.Account.Zip, Username: response.data.Account.GWUserID, IsGWUpdate: true })
                        if (registerAccount_response && registerAccount_response.data) {
                            if (registerAccount_response.data.Result === "Account Migrated Successfully" || registerAccount_response.data.Result === "Account Created") {
                                handleLogin(response.data.Account.EmailAddress, user.Password)
                            } else {
                                setRegistrationErrorMessage("Error: " + registerAccount_response.data.Result)
                            }
                        } else {
                            setRegistrationErrorMessage("Error: an error occured while registering the account.")
                        }
                    }
                }
            }
        }
        setShowSpinner(false)
    }

    const handleLogin = async (emailAddress, password) => {
        const response = await verifyLogin(emailAddress, password, 'login')
        if (response && response.data) {
            const { Result, Tkn } = response.data
            if (Result === 'Success') {
                const url = `https://${getDomain('account')}/home?tkn=${Tkn}`
                window.location = url
            } else if (Result === 'Loaded By Temp Password') {
                dispatch({
                    type: 'ADD_EMAIL',
                    payload: {
                        emailAddress,
                        tempPassword: password
                    }
                })
                window.location = '/my-account/temp-password'
            } else if (Result === 'Inactive') {
                window.location = '/my-account/policy-inactive/'
            }
            else{
                window.location = '/my-account/'
                setShowSpinner(false)
            }
        } else {
            setShowSpinner(false)
        }
    }

    const updateTextFields = (text, field, isDate) => {
        const clonedUser = { ...user }
        if (isDate && text) {
            clonedUser[field] = text
        } else {
            clonedUser[field] = text
        }
        setUser(clonedUser)
    }

    const isFormValid = () => {
        let response = false
        if (
            user.FirstName.length === 0 ||
            user.LastName.length === 0 ||
            user.EmailAddress.length === 0 ||
            user.Password.length === 0 ||
            user.DOB.length === 0 ||
            user.RetypePassword.length === 0 ||
            user.SecurityQuestion1.length === 0 ||
            user.SecurityQuestion2.length === 0 ||
            user.SecurityQuestion3.length === 0 ||
            user.SecurityAnswer1.length === 0 ||
            user.SecurityAnswer2.length === 0 ||
            user.SecurityAnswer3.length === 0
        ) {
            response = true
        }
        if (!response) {
            if (new Date(user.DOB).toString() === 'Invalid Date') {
                response = true
            }
        }
        if (!response) {
            const regEx = /\S+@\S+\.\S+/
            if (user.EmailAddress.length > 0 && !regEx.test(user.EmailAddress)) {
                response = true
            }
        }
        if (!response) {
            if (user.Password.length > 0 && user.RetypePassword.length > 0) {
                if (user.Password === user.RetypePassword) {
                    const isValidLength = /^(?=.*[0-9])(?=.*[A-Z]).{5,}$/
                    if (!isValidLength.test(user.Password)) {
                        response = true
                    } else {
                        response = false
                    }
                } else {
                    response = true
                }
            }
        }
        return response
    }

    const validatePassword = (password, retypePassword) => {
        setPasswordValidationError(false)
        setPasswordMatchValidationError(false)
        if (password.length > 0) {
            const isValidLength = /^(?=.*[0-9])(?=.*[A-Z]).{5,}$/
            if (!isValidLength.test(password)) {
                setPasswordValidationError(true)
            } else {
                setPasswordValidationError(false)
            }
        }
        if (retypePassword.length > 0) {
            const isValidLength = /^(?=.*[0-9])(?=.*[A-Z]).{5,}$/
            if (!isValidLength.test(retypePassword)) {
                setPasswordValidationError(true)
            } else {
                setPasswordValidationError(false)
            }
        }
        if (password.length > 0 && retypePassword.length > 0) {
            if (password !== retypePassword) {
                setPasswordMatchValidationError(true)
            } else {
                setPasswordMatchValidationError(false)
            }
        }
    }

    const validateDuplicateEmail = (duplicateEmail, retypeDuplicateEmail) => {
        setDuplicateEmailErrorMessage(false)
        setDuplicateEmailValidationError(false)
        setDuplicateEmailMatchValidationError(false)
        if (duplicateEmail.length > 0) {
            const emailFormat = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
            if (!emailFormat.test(duplicateEmail)) {
                setDuplicateEmailValidationError(true)
            } else {
                setDuplicateEmailValidationError(false)
            }
        }
        if (retypeDuplicateEmail.length > 0) {
            const emailFormat = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
            if (!emailFormat.test(retypeDuplicateEmail)) {
                setDuplicateEmailValidationError(true)
            } else {
                setDuplicateEmailValidationError(false)
            }
        }
        if (duplicateEmail.length > 0 && retypeDuplicateEmail.length > 0) {
            if (duplicateEmail !== retypeDuplicateEmail) {
                setDuplicateEmailMatchValidationError(true)
            } else {
                setDuplicateEmailMatchValidationError(false)
            }
        }
    }

    const validateDuplicateUsername = (duplicateUsername, retypeDuplicateUsername) => {
        setDuplicateUsernameValidationError(false)
        setDuplicateUsernameMatchValidationError(false)
        if (duplicateUsername.length > 0) {
            const usernameFormat = /^[\d\D]{3,}$/
            if (!usernameFormat.test(duplicateUsername)) {
                setDuplicateUsernameValidationError(true)
            } else {
                setDuplicateUsernameValidationError(false)
            }
        }
        if (retypeDuplicateUsername.length > 0) {
            const usernameFormat = /^[\d\D]{3,}$/
            if (!usernameFormat.test(retypeDuplicateUsername)) {
                setDuplicateEmailValidationError(true)
            } else {
                setDuplicateEmailValidationError(false)
            }
        }
        if (duplicateUsername.length > 0 && retypeDuplicateUsername.length > 0) {
            if (duplicateUsername !== retypeDuplicateUsername) {
                setDuplicateUsernameMatchValidationError(true)
            } else {
                setDuplicateUsernameMatchValidationError(false)
            }
        }
    }
    const handleKeyDown = event => {
        if (event.key === 'Enter' && !isFormValid()) {
            handleSubmit()
        }
    }

    const securityQuestion1 = securityQuestions.filter(question => question.Code === user.SecurityQuestion1)[0]
    const securityQuestion2 = securityQuestions.filter(question => question.Code === user.SecurityQuestion2)[0]
    const securityQuestion3 = securityQuestions.filter(question => question.Code === user.SecurityQuestion3)[0]


    const handleValidateUsername = async (event) => {
        /*
        1. user types in an email with no account and no guidewire row = account not found
            -verifyLogin: Not Found
            -checkGWAccount: No Account Found
        2. user types in an email with no account and a guidewire row where converted = "no" = proceed
            -verifyLogin: Non Converted GW User
            -checkGWAccount: Success
        3. user types in an email with no account and a guidewire row where converted = "yes" = user deleted account error
            -verifyLogin: Not Found
            -checkGWAccount: Success

        4. user types in an email with a policy account but no guidewire row = account already updated
            -verifyLogin: Incorrect Password
            -checkGWAccount: 
        5. user types in an email with a policy account and a guidewire row where converted = "no" = proceed as usual (duplicate email handled later)
            -verifyLogin: Policy User and Non Converted GW User
            -checkGWAccount: Success
        6. user types in an email with a policy account and all guidewire rows are converted="yes" (none="no") = account already updated
            -verifyLogin: Incorrect Password
            -checkGWAccount:
        */
        if (user.EmailAddress !== "") {
            setShowSpinner(true);
            const verifylogin_resp = await verifyLogin(user.EmailAddress, "", "login")
            if (verifylogin_resp && verifylogin_resp.data && verifylogin_resp.data.Result && verifylogin_resp.data.Result === "Incorrect Password") {
                //user has a policy account and may have a converted gw account
                setUsernameNotFound(false)
                setAlreadyConverted(true)
            } else {
                let response = await checkGWAccount({ EmailAddress: user.EmailAddress })
                if (response && response.data && response.data.Result === 'Success') {
                    setUsernameVerified(true)
                    setAlreadyConverted(false)
                    setUsernameNotFound(false)
                    setUserGW(response.data.Account)
                } else {
                    setAlreadyConverted(false)
                    setUsernameNotFound(true)
                }
            }
            setShowSpinner(false)
        }

    }

    const handleSubmit = async () => {
        setPolicyholderInformationError(false);
        setAlreadyConverted(false);
        const checkgwaccount_resp = await checkGWAccount({ FirstName: user.FirstName.replace(/\s/g,''), LastName: user.LastName.replace(/\s/g,''), DOB: user.DOB.replace(/\s/g,''), EmailAddress: user.EmailAddress.replace(/\s/g,'') })
        if (checkgwaccount_resp && checkgwaccount_resp.data) {
            const { Result, Account } = checkgwaccount_resp.data
            if (Result === "Success") {
                if (Account.Converted === "yes") {
                    setAlreadyConverted(true);
                } else {
                    //not converted
                    const isusernameregistered_resp = await isUsernameRegistered({ PolicyNumber: null, ZipCode: null, Username: Account.GWUserID })
                    const verifylogin_resp = await verifyLogin(Account.EmailAddress, "", "login")
                    if (verifylogin_resp && verifylogin_resp.data) {
                        const { Result, Tkn } = verifylogin_resp.data
                        if (Result === "Non Converted GW User" || Result === "Not Found") {
                            //no active policy account exists for the new email: skip to agreement
                            if (isusernameregistered_resp.data.Result !== "Registered") {
                                setShowAgreement(true)
                            }
                        } else if (Result === "Policy User and Non Converted GW User" || Result === "Success" || Result === "Incorrect Password") {
                            //an active policy account exists for that email: show duplicate email error message
                            setShowAgreement(false);
                            setShowDuplicateEmailPage(true);
                        }
                    }
                    if (isusernameregistered_resp && isusernameregistered_resp.data) {
                        if (isusernameregistered_resp.data.Result === "Registered") {
                            setIsDuplicateUsername(true)
                            setShowAgreement(false)
                        }
                        else {
                            setIsDuplicateUsername(false)
                            // if (showDuplicateEmailPage) {
                            //     setShowAgreement(false)
                            // } else {
                            //     setShowAgreement(true)
                            // }
                        }
                    }
                }
            } else {
                //information does not match
                setPolicyholderInformationError(true);
            }
        };
    }

    return (
        <div className={`${UpdateStyle["update-page"]}`}>
            {!showAgreement && !showDuplicateEmailPage && !isDuplicateUsername && (
                <>
                    <EmptyHeaderHero title="My Account Update" className={`${UpdateStyle["main-title"]} ${UpdateStyle["no-width"]}`}/>
                    <div className={`${UpdateStyle["register-screen"]}`}>
                        <Row>
                            <Card className={`${UpdateStyle["register-card"]}`}>
                                <p className={`${UpdateStyle["update-copy-black"]}`} style={{ paddingBottom: "0px" }}>My Account has been updated with great new features:</p>
                                <ul className={`${UpdateStyle["update-copy-black"]}`}>
                                    <li>Enroll in Autopay</li>
                                    <li>Sign up for Text-to-Pay</li>
                                    <li>Submit online change requests</li>
                                </ul>
                                {/* {!router.query.verifiedUser ? <> */}
                                <p className={`${UpdateStyle["update-copy-black"]}`} style={{ paddingBottom: "0px" }}>For security purposes, please complete the following:</p>
                                <h5 className={`${UpdateStyle["sub-label"]}`}>Account Username or Email Address</h5>
                                <Row className={`${UpdateStyle["row-1"]}`} style={{flexWrap: "nowrap"}}>
                                    <Col style={{flexShrink: "0.1"}} xl={{ span: 8, align: "middle" }} xs={{ span: 24 }}>
                                        <Form.Item className={`${UpdateStyle["margin-bottom-14"]}`}>
                                            <Input
                                                autoComplete="new-password"
                                                className={`${UpdateStyle["input-text"]}`}
                                                onChange={event => updateTextFields(event.target.value, 'EmailAddress')}
                                                onKeyDown={handleKeyDown}
                                                onBlur={event => handleValidateUsername(event)}
                                                placeholder="Account Username or Email Address*"
                                                value={user.EmailAddress}
                                                disabled={usernameVerified}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col className={`${UpdateStyle["spinnerCol"]}`} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", height: "42px", paddingLeft: "10px", flexShrink: "0.9" }} xl={{ span: 8 }} xs={{ span: 24 }}>
                                        {showSpinner && <Spin indicator={antIcon} />}
                                        {usernameVerified && <CheckCircleFilled style={{ color: "rgb(42, 90, 155)" }}></CheckCircleFilled>}
                                    </Col>
                                </Row>
                                {usernameNotFound && !alreadyConverted && (
                                    <Row style={{ marginBottom: "5px" }}>
                                        <Col className={`${UpdateStyle["text-wrapper"]}`} style={{textAlign: "left"}} span={24}>
                                            <Text className={`${UpdateStyle["error-msg"]}`}>
                                                Your username cannot be found. Please try again or click <a className={`${UpdateStyle["error-msg"]}`} href="https://www.cure.com/my-account/forgot-email">Forgot Username / Email Address</a>.
                                            </Text>
                                        </Col>
                                    </Row>
                                )}
                                {alreadyConverted && !usernameNotFound && (
                                    <Row style={{ marginBottom: "5px" }}>
                                        <Col className={`${UpdateStyle["text-wrapper"]}`} style={{textAlign: "left"}} span={24}>
                                            <Text className={`${UpdateStyle["error-msg"]}`}>
                                                Username/email address has already been updated! Please log in on the <a className={`${UpdateStyle["error-msg"]}`} onClick={() => router.push('/my-account/')}>My Account log in page</a>.
                                            </Text>
                                        </Col>
                                    </Row>
                                )}
                                {/* </> : <>
                                    <Row style={{ margin: "25px 0px" }}>
                                        <Text className="verified-user-copy">
                                            Your Username: <span>{router.query.verifiedUser}</span>
                                        </Text>
                                        <CheckCircleFilled style={{ color: "rgb(42, 90, 155)", display: "flex", flexDirection: "column", justifyContent: "center", paddingLeft: "10px" }}></CheckCircleFilled>
                                    </Row>
                                    <p className={`${UpdateStyle["update-copy-black"]}`}>For security purposes, please complete the following:</p>
                                </>
                                } */}

                                <h5 className={`${UpdateStyle["sub-label"]}`}>Policyholder Information</h5>
                                <Row>
                                    <Col xl={{ span: 8 }} xs={{ span: 24 }} style={{ marginRight: "10px" }}>
                                        <Form.Item className={`${UpdateStyle["margin-bottom-14"]}`}>
                                            <Input
                                                autoComplete="new-password"
                                                className={`${UpdateStyle["input-text"]}`}
                                                onKeyDown={handleKeyDown}
                                                placeholder="First Name*"
                                                value={user.FirstName}
                                                onChange={event => updateTextFields(event.target.value, 'FirstName', true)}
                                                disabled={!usernameVerified}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xl={{ span: 8, offset: 1 }} xs={{ span: 24 }}>
                                        <Form.Item className={`${UpdateStyle["margin-bottom-14"]}`}>
                                            <Input
                                                autoComplete="new-password"
                                                className={`${UpdateStyle["input-text"]}`}
                                                onKeyDown={handleKeyDown}
                                                placeholder="Last Name*"
                                                value={user.LastName}
                                                onChange={event => updateTextFields(event.target.value, 'LastName', true)}
                                                disabled={!usernameVerified}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={{ span: 8 }} xs={{ span: 24 }}>
                                        <Form.Item className={`${UpdateStyle["margin-bottom-12"]}`} style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                            <InputMask
                                                className={`${UpdateStyle["dob"]} ${!usernameVerified && "disabled"}`}
                                                mask="99/99/9999"
                                                style={{ backgroundColor: !usernameVerified ? "#f5f5f5" : "transparent", cursor: !usernameVerified ? "not-allowed" : "unset", color: !usernameVerified ? "rgba(0,0,0,.25)" : "#000000" }}
                                                onChange={event => updateTextFields(event.target.value, 'DOB', true)}
                                                placeholder="DOB (mm/dd/yyyy)*"
                                                value={user.DOB}
                                                disabled={!usernameVerified}
                                                autoComplete="new-password"
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <h5 className={`${UpdateStyle["sub-label"]}`} style={{ marginBottom: "0px" }}>Create a Password</h5>
                                <Row>
                                    <Col className={`${UpdateStyle["text-wrapper"]}`} span={24}>
                                        <p className={`${UpdateStyle["update-copy-black"]}`} style={{ paddingTop: "0px" }}>Passwords must have at least 5 characters with 1 number and 1 capital letter.</p>
                                    </Col>
                                </Row>
                                <Row style={{ gap: "10px" }}>
                                    <Col className={`${UpdateStyle["account-margin"]}`} xl={{ span: 8 }} xs={{ span: 24 }}>
                                        <Form.Item className={`${UpdateStyle["margin-bottom-4"]}`}>
                                            <Input.Password
                                                autoComplete="new-password"
                                                className={`${UpdateStyle["input-text"]}`}
                                                iconRender={visible => (visible ? 'Hide' : 'Show')}
                                                onBlur={event => validatePassword(event.target.value, user.RetypePassword)}
                                                onChange={event => updateTextFields(event.target.value, 'Password')}
                                                onKeyDown={handleKeyDown}
                                                placeholder="Password*"
                                                type="password"
                                                value={user.Password}
                                                disabled={!usernameVerified}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col className={`${UpdateStyle["account-margin"]}`} xl={{ span: 8, offset: 1 }} xs={{ span: 24 }}>
                                        <Form.Item className={`${UpdateStyle["margin-bottom-4"]}`}>
                                            <Input.Password
                                                autoComplete="new-password"
                                                className={`${UpdateStyle["input-text"]}`}
                                                iconRender={visible => (visible ? 'Hide' : 'Show')}
                                                onBlur={event => validatePassword(user.Password, event.target.value)}
                                                onChange={event => updateTextFields(event.target.value, 'RetypePassword')}
                                                onKeyDown={handleKeyDown}
                                                placeholder="Confirm Password*"
                                                type="password"
                                                value={user.RetypePassword}
                                                disabled={!usernameVerified}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                {passwordValidationError && (
                                    <Row className={`${UpdateStyle["margin-top-12"]}`}>
                                        <Col className={`${UpdateStyle["text-wrapper"]}`} span={24}>
                                            <Text className={`${UpdateStyle["error-msg"]}`}>
                                                Passwords must have at least 5 characters with 1 number and 1 capital letter.
                                            </Text>
                                        </Col>
                                    </Row>
                                )}
                                {!passwordValidationError && passwordMatchValidationError && (
                                    <Row className={`${UpdateStyle["margin-top-12"]}`}>
                                        <Col className={`${UpdateStyle["text-wrapper"]}`} span={24}>
                                            <Text className={`${UpdateStyle["error-msg"]}`}>Password and Confirm Password do not match.</Text>
                                        </Col>
                                    </Row>
                                )}
                                <h5 className={`${UpdateStyle["sub-label"]} ${UpdateStyle["account-margin"]} ${UpdateStyle["security"]} ${UpdateStyle["margin-bottom-0"]}`}>Security Questions</h5>
                                <p className={`${UpdateStyle["update-copy-black"]}`} style={{ paddingTop: "0px" }}>
                                    Please note: In the future, security answers need to be entered exactly as they are here.
                                </p>
                                <Row className={`${UpdateStyle["margin-bottom-14"]}`} style={{ gap: "10px" }}>
                                    <Col className={`${UpdateStyle["account-margin-select"]}`} xl={{ span: 8 }} xs={{ span: 24 }}>
                                        <Select
                                            dropdownClassName={`${UpdateStyle["security-questions"]}`}
                                            onChange={e => {
                                                handleQuestionValChange(e, 0)
                                                updateTextFields(e, 'SecurityQuestion1')
                                            }}
                                            placeholder="Select a security question*"
                                            value={securityQuestion1?.Text}
                                            disabled={!usernameVerified}
                                        >
                                            {getAvailableOptions().map((question, index) => (
                                                <Option key={index} value={question.Code}>
                                                    {question.Text}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Col>
                                    <Col className={`${UpdateStyle["account-margin"]}`} xl={{ span: 8, offset: 1 }} xs={{ span: 24 }}>
                                        <Input
                                            autoComplete="new-password"
                                            className={`${UpdateStyle["input-text"]}`}
                                            onChange={event => updateTextFields(event.target.value, 'SecurityAnswer1')}
                                            onKeyDown={handleKeyDown}
                                            placeholder="Security Answer 1*"
                                            value={user.SecurityAnswer1}
                                            disabled={!usernameVerified}
                                        />
                                    </Col>
                                </Row>
                                <Row className={`${UpdateStyle["margin-bottom-14"]}`} style={{ gap: "10px" }}>
                                    <Col className={`${UpdateStyle["account-margin-select"]}`} xl={{ span: 8 }} xs={{ span: 24 }}>
                                        <Select
                                            dropdownClassName={`${UpdateStyle["security-questions"]}`}
                                            onChange={e => {
                                                handleQuestionValChange(e, 1)
                                                updateTextFields(e, 'SecurityQuestion2')
                                            }}
                                            placeholder="Select a security question*"
                                            value={securityQuestion2?.Text}
                                            disabled={!usernameVerified}
                                        >
                                            {getAvailableOptions().map((question, index) => (
                                                <Option key={index} value={question.Code}>
                                                    {question.Text}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Col>
                                    <Col className={`${UpdateStyle["account-margin"]}`} xl={{ span: 8, offset: 1 }} xs={{ span: 24 }}>
                                        <Input
                                            autoComplete="new-password"
                                            className={`${UpdateStyle["input-text"]}`}
                                            onChange={event => updateTextFields(event.target.value, 'SecurityAnswer2')}
                                            onKeyDown={handleKeyDown}
                                            placeholder="Security Answer 2*"
                                            value={user.SecurityAnswer2}
                                            disabled={!usernameVerified}
                                        />
                                    </Col>
                                </Row>
                                <Row className={`${UpdateStyle["margin-bottom-14"]}`} style={{ gap: "10px" }}>
                                    <Col className={`${UpdateStyle["account-margin-select"]}`} xl={{ span: 8 }} xs={{ span: 24 }}>
                                        <Select
                                            dropdownClassName={`${UpdateStyle["security-questions"]}`}
                                            onChange={e => {
                                                handleQuestionValChange(e, 2)
                                                updateTextFields(e, 'SecurityQuestion3')
                                            }}
                                            placeholder="Select a security question*"
                                            value={securityQuestion3?.Text}
                                            disabled={!usernameVerified}
                                        >
                                            {getAvailableOptions().map((question, index) => (
                                                <Option key={index} value={question.Code}>
                                                    {question.Text}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Col>
                                    <Col className={`${UpdateStyle["account-margin"]}`} xl={{ span: 8, offset: 1 }} xs={{ span: 24 }}>
                                        <Input
                                            autoComplete="new-password"
                                            className={`${UpdateStyle["input-text"]}`}
                                            onChange={event => updateTextFields(event.target.value, 'SecurityAnswer3')}
                                            onKeyDown={handleKeyDown}
                                            placeholder="Security Answer 3*"
                                            value={user.SecurityAnswer3}
                                            disabled={!usernameVerified}
                                        />
                                    </Col>
                                </Row>
                                {/* <Row className={`${UpdateStyle["margin-bottom-14"]}`}>
                                    <p className={`${UpdateStyle["update-copy-black"]}`}>Once your account is updated, your log in can be used for our CURE mobile app.</p>
                                </Row> */}
                                {message && (
                                    <Row className={`${UpdateStyle["margin-bottom-14"]}`}>
                                        <Col className={`${UpdateStyle["account-margin-select"]}`} xl={{ span: 8 }} xs={{ span: 24 }}>
                                            {message !== 'Account Created' && <Text className={`${UpdateStyle["error-msg"]}`}>{message}</Text>}
                                            {message === 'Account Created' && (
                                                <>
                                                    <Text className={`${UpdateStyle["success-msg"]}`}>Thank you. Your account has been created.</Text>{' '}
                                                    <a className={`${UpdateStyle["success-msg"]}`} href="/my-account" target="_blank">
                                                        Log in now.
                                                    </a>
                                                </>
                                            )}
                                        </Col>
                                    </Row>
                                )}
                                {policyholderInformationError && (
                                    <Row className={`${UpdateStyle["margin-top-12"]}`}>
                                        <Col className={`${UpdateStyle["text-wrapper"]}`} span={24}>
                                            <Text className={`${UpdateStyle["error-msg"]}`}>
                                                The Policyholder Information (First Name, Last Name and/or DOB) you entered above does not match what we have on file for your username. Please try again.
                                            </Text>
                                        </Col>
                                    </Row>
                                )}
                                <Row className={`${UpdateStyle["submit-container"]}`}>
                                    <Form.Item className={`${UpdateStyle["btn-control"]} ${UpdateStyle["button-submit-form"]}`}>
                                        <Space>
                                            <Button
                                                className={`${UpdateStyle["register"]} ${UpdateStyle["register-form-button"]}`}
                                                disabled={user.FirstName.length === 0 ||
                                                    user.LastName.length === 0 ||
                                                    user.EmailAddress.length === 0 ||
                                                    user.Password.length === 0 ||
                                                    user.DOB.length === 0 ||
                                                    user.RetypePassword.length === 0 ||
                                                    user.SecurityQuestion1.length === 0 ||
                                                    user.SecurityQuestion2.length === 0 ||
                                                    user.SecurityQuestion3.length === 0 ||
                                                    user.SecurityAnswer1.length === 0 ||
                                                    user.SecurityAnswer2.length === 0 ||
                                                    user.SecurityAnswer3.length === 0}
                                                onClick={() => handleSubmit()}
                                            >
                                                Register
                                            </Button>
                                        </Space>
                                    </Form.Item>
                                </Row>
                            </Card>
                        </Row>
                    </div>
                </>
            )}
            {showAgreement && !showDuplicateEmailPage && !isDuplicateUsername && (
                <div className={`${UpdateStyle["register-screen"]}`}>
                    <EmptyHeaderHero minHeight={true} title="Agreement To Conduct Business Electronically" className={`${UpdateStyle["no-width"]}`} />
                    <div className={`${UpdateStyle["electronics-terms-and-condition-screen"]}`}>
                        <Row justify="center">
                            <Card className={`${UpdateStyle["electronics-terms-and-condition-card"]}`}>
                                <p className={`${UpdateStyle["sub-text"]} ${UpdateStyle["margin-bottom-12"]}`}>
                                    You <span className={`${UpdateStyle["bold"]}`}>MUST</span> read through and scroll to the bottom.
                                </p>
                                <p className={`${UpdateStyle["sub-text"]}`}>
                                    State law permits parties which have agreed to conduct transactions by electronic means to enter into
                                    an agreement online provided the requirements of the applicable laws are met. State law requires CURE
                                    to obtain your consent to electronically sign insurance application documents and to provide you with
                                    an electronic copy of your documents.
                                </p>

                                <h5 className={`${UpdateStyle["small"]}`}>An Electronic Signature:</h5>
                                <p className={`${UpdateStyle["sub-text"]}`}>
                                    An electronic signature is an electronic sound, symbol, or process attached to or logically associated
                                    with a record that is executed or adopted by a person to demonstrate their intent to sign the record.
                                    When you decide to apply online for insurance coverage with CURE, you are required to electronically
                                    sign the following documents: an Agreement to Conduct Business Electronically, an Application, and a
                                    Power of Attorney.
                                </p>
                                <p className={`${UpdateStyle["sub-text"]}`}>
                                    Once coverage is bound, CURE will send you a Coverage Selection Form showing your coverage selections
                                    on which you may make any changes and/or corrections. A Buyer&apos;s Guide, the Insurance Scenarios,
                                    and the Consumer Bill of Rights will also accompany the Coverage Selection Form.
                                </p>
                                <p className={`${UpdateStyle["sub-text"]}`}>
                                    You also have the option to obtain a copy of your application documents in a non-electronic format if
                                    you so choose. To receive paper copies of documents that are delivered electronically, or to withdraw
                                    consent to receive such documents electronically, please follow the instructions set forth below in
                                    the section titled &quot;WITHDRAWAL OF CONSENT & REQUEST FOR PAPER DOCUMENTS&quot;
                                </p>

                                <h5 className={`${UpdateStyle["small"]}`}>Withdrawal of Consent and Request for Paper Documents:</h5>
                                <p className={`${UpdateStyle["sub-text"]}`}>
                                    You may withdraw your consent to obtain your application documents electronically or request paper
                                    copies of documents that are delivered electronically by sending a written request to CURE, Attention:
                                    Underwriting Department, 214 Carnegie Center, Suite 301, Princeton, New Jersey 08540.
                                </p>

                                <h5 className={`${UpdateStyle["small"]}`}>Application to CURE by Telephone:</h5>
                                <p className={`${UpdateStyle["sub-text"]}`}>
                                    If you have obtained a quote with CURE but do not want to apply for a policy using the electronic
                                    transaction option, please call us at 1-800-535-2873. Our representatives will be glad to assist you
                                    with your automobile insurance needs.
                                </p>

                                <h5 className={`${UpdateStyle["small"]}`}>
                                    Hardware and Software Requirements for Access to and Retention of Electronic Records:
                                </h5>
                                <p className={`${UpdateStyle["sub-text"]}`}>
                                    To access CURE&apos;s website and obtain electronic application documents, you must have the ability
                                    to access the Internet using a web browser such as Microsoft Internet Explorer, Google Chrome or
                                    Firefox. A valid e-mail address, Adobe Acrobat Reader and a printer are also required in order to
                                    receive, view, and print certain application documents. CURE uses modern encrypt protocols while
                                    accessing sensitive information during online transactions.
                                </p>
                                <p className={`${UpdateStyle["sub-text"]}`}>
                                    Should CURE change hardware and software requirements for access to and retention of electronic
                                    records, such that the change creates a material risk that you will not be able to access or retain
                                    your subsequent electronic records (the subject of your prior consent), CURE will provide you with a
                                    statement of:
                                </p>
                                <p className={`${UpdateStyle["sub-text"]}`}>
                                    <b>•</b> The revised hardware and software requirements for access to and retention of your electronic
                                    records.
                                </p>
                                <p className={`${UpdateStyle["sub-text"]}`}>
                                    <b>•</b> Your right to withdraw consent without imposition of a fee for such withdrawal and without
                                    the imposition of any condition or consequence that was not disclosed above.
                                </p>

                                <h5 className={`${UpdateStyle["small"]}`}>Statement of Understanding:</h5>
                                {selectedState === 'NJ' && (
                                    <>
                                        <p className={`${UpdateStyle["sub-text"]}`}>
                                            I understand that my application documents will be sent to me electronically if I consent to
                                            electronically sign my application. I also understand that I may make changes to my coverages
                                            using the Coverage Selection Form that will be sent to me along with the Buyer&apos;s Guide, the
                                            Insurance Scenarios, and the Consumer Bill of Rights.
                                        </p>
                                        <p className={`${UpdateStyle["sub-text"]}`}>
                                            In addition, I agree to accept all other documents relating to my policy electronically provided
                                            they are not required by law to be mailed, with the knowledge that I may withdraw consent to
                                            receive those insurance documents electronically by following the instructions set forth above in
                                            the section titled &quot;WITHDRAWAL OF CONSENT & REQUEST FOR PAPER DOCUMENTS.&quot;
                                        </p>
                                    </>
                                )}
                                {selectedState === 'PA' && (
                                    <>
                                        <p className={`${UpdateStyle["sub-text"]}`}>
                                            I understand that my application documents will be sent to me electronically if I consent to
                                            electronically sign my application. I agree to accept all other documents relating to my policy
                                            electronically provided they are not required by law to be mailed, with the knowledge that I may
                                            withdraw consent to receive those insurance documents electronically by following the instructions
                                            set forth above in the section titled &quot;WITHDRAWAL OF CONSENT & REQUEST FOR PAPER
                                            DOCUMENTS.&quot;
                                        </p>
                                        <p className={`${UpdateStyle["sub-text"]}`}>
                                            In addition, I agree to accept all other documents relating to my policy electronically provided
                                            they are not required by law to be mailed, with the knowledge that I may withdraw consent to
                                            receive those insurance documents electronically by following the instructions set forth above in
                                            the section titled &quot;WITHDRAWAL OF CONSENT & REQUEST FOR PAPER DOCUMENTS.&quot;
                                        </p>
                                    </>
                                )}
                                <p className={`${UpdateStyle["sub-text"]}`}>
                                    I understand that I have the right to withdraw consent to receive my insurance application documents
                                    electronically, without imposition of a fee for such withdrawal and without the imposition of any
                                    condition or consequence that was not disclosed above, by following the instructions set forth above
                                    in the section titled &quot;WITHDRAWAL OF CONSENT & REQUEST FOR PAPER DOCUMENTS.&quot;
                                </p>
                                <p className={`${UpdateStyle["sub-text"]}`}>
                                    I understand that I have the option to obtain a copy of my application documents in a non-electronic
                                    format by following the instructions set forth above in the section titled &quot;WITHDRAWAL OF CONSENT
                                    & REQUEST FOR PAPER DOCUMENTS.&quot;
                                </p>
                                <p className={`${UpdateStyle["sub-text"]}`}>
                                    I understand that withdrawal of consent will terminate the online transaction which I have begun.
                                </p>
                                <p />
                                <Checkbox checked={isAgreed} onChange={event => setIsAgreed(event.target.checked)}>
                                    I agree to the terms above.
                                </Checkbox>
                                <p />
                                <p />
                                <Row className={`${UpdateStyle["agreement-submit-container"]}`}>
                                    <Form.Item className={`${UpdateStyle["btn-control"]} ${UpdateStyle["button-submit-form"]}`}>
                                        <Button className={`${UpdateStyle["form-button"]}`} disabled={!isAgreed} onClick={handleAgree}>
                                            Agree
                                        </Button>
                                        {showSpinner && <Spin indicator={antIcon} style={{ marginLeft: 24 }} />}
                                    </Form.Item>
                                </Row>
                                {registrationErrorMessage && (<Row className={`${UpdateStyle["margin-top-12"]}`}>
                                    <Col className={`${UpdateStyle["text-wrapper"]}`} span={24}>
                                        <Text className={`${UpdateStyle["error-msg"]}`}>
                                            {registrationErrorMessage}
                                        </Text>
                                    </Col>
                                </Row>)}
                            </Card>
                        </Row>
                    </div>
                </div>
            )}
            {!showAgreement && (showDuplicateEmailPage || isDuplicateUsername) && (
                <>
                    <EmptyHeaderHero title={`Duplicate ${showDuplicateEmailPage ? "Email" : "Username"}`} className={`${UpdateStyle["no-width"]}`} />
                    <div className={`${UpdateStyle["register-screen"]}`}>

                        <div className={`${UpdateStyle["electronics-terms-and-condition-screen"]}`}>
                            <Row justify="center">
                                <Card className={`${UpdateStyle["electronics-terms-and-condition-card"]}`}>
                                    <Row>
                                        <p className={`${UpdateStyle["sub-text"]}`} style={{ marginBottom: "14px !important" }}>
                                            The {showDuplicateEmailPage ? "email address" : "username"} associated with your {showDuplicateEmailPage ? "username" : "account"} has already been registered with another account.
                                            For security purposes, the My Account portal only allows {showDuplicateEmailPage ? "an email address" : "a username"} to be used for one account.
                                            Please provide a new {showDuplicateEmailPage ? "email address" : "username"} to use for your My Account.
                                        </p>
                                    </Row>
                                    <Row>
                                        <Col xl={{ span: 8 }} xs={{ span: 24 }}>
                                            <Form.Item className={`${UpdateStyle["margin-bottom-14"]}`}>
                                                <Input
                                                    autoComplete="off"
                                                    className={`${UpdateStyle["input-text"]}`}
                                                    placeholder={showDuplicateEmailPage ? "Email Address*" : "Username*"}
                                                    value={showDuplicateEmailPage ? duplicateEmailNewEmail : duplicateUsernameNewUsername}
                                                    onChange={showDuplicateEmailPage ? (event => setDuplicateEmailNewEmail(event.target.value)) : (event => setDuplicateUsernameNewUsername(event.target.value))}
                                                    onBlur={showDuplicateEmailPage ? (event => validateDuplicateEmail(event.target.value, duplicateEmailConfirmEmail)) : (event => validateDuplicateUsername(event.target.value, duplicateUsernameConfirmUsername))}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={{ span: 8 }} xs={{ span: 24 }}>
                                            <Form.Item className={`${UpdateStyle["margin-bottom-14"]}`}>
                                                <Input
                                                    autoComplete="off"
                                                    className={`${UpdateStyle["input-text"]}`}
                                                    placeholder={showDuplicateEmailPage ? "Confirm Email Address*" : "Confirm Username*"}
                                                    value={showDuplicateEmailPage ? duplicateEmailConfirmEmail : duplicateUsernameConfirmUsername}
                                                    onChange={showDuplicateEmailPage ? (event => setDuplicateEmailConfirmEmail(event.target.value)) : (event => setDuplicateUsernameConfirmUsername(event.target.value))}
                                                    onBlur={showDuplicateEmailPage ? (event => validateDuplicateEmail(duplicateEmailNewEmail, event.target.value)) : (event => validateDuplicateUsername(duplicateUsernameNewUsername, event.target.value))}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    {duplicateEmailValidationError && (
                                        <Row className={`${UpdateStyle["margin-top-12"]}`}>
                                            <Col className={`${UpdateStyle["text-wrapper"]}`} span={24}>
                                                <Text className={`${UpdateStyle["error-msg"]}`}>
                                                    Please enter a valid email address.
                                                </Text>
                                            </Col>
                                        </Row>
                                    )}
                                    {duplicateUsernameValidationError && (
                                        <Row className={`${UpdateStyle["margin-top-12"]}`}>
                                            <Col className={`${UpdateStyle["text-wrapper"]}`} span={24}>
                                                <Text className={`${UpdateStyle["error-msg"]}`}>
                                                    Usernames must be at least 3 characters. Can contain letters, special characters and numbers.
                                                </Text>
                                            </Col>
                                        </Row>
                                    )}
                                    {duplicateUsernameErrorMessage && (
                                        <Row className={`${UpdateStyle["margin-top-12"]}`}>
                                            <Col className={`${UpdateStyle["text-wrapper"]}`} span={24}>
                                                <Text className={`${UpdateStyle["error-msg"]}`}>This username is already in use.</Text>
                                            </Col>
                                        </Row>
                                    )}
                                    {!duplicateEmailValidationError && duplicateEmailMatchValidationError && (
                                        <Row className={`${UpdateStyle["margin-top-12"]}`}>
                                            <Col className={`${UpdateStyle["text-wrapper"]}`} span={24}>
                                                <Text className={`${UpdateStyle["error-msg"]}`}>Email and Confirm Email do not match.</Text>
                                            </Col>
                                        </Row>
                                    )}
                                    {duplicateUsernameMatchValidationError && (
                                        <Row className={`${UpdateStyle["margin-top-12"]}`}>
                                            <Col className={`${UpdateStyle["text-wrapper"]}`} span={24}>
                                                <Text className={`${UpdateStyle["error-msg"]}`}>Username and Confirm Username do not match.</Text>
                                            </Col>
                                        </Row>
                                    )}
                                    <Row>
                                        <p className={`${UpdateStyle["sub-text"]}`}>
                                            Please note: This change will not affect your communication preferences with CURE.
                                        </p>
                                    </Row>
                                    {duplicateEmailErrorMessage && (
                                        <Row className={`${UpdateStyle["margin-top-12"]}`}>
                                            <Col className={`${UpdateStyle["text-wrapper"]}`} span={24}>
                                                <Text className={`${UpdateStyle["error-msg"]}`}>This email address is already in use.</Text>
                                            </Col>
                                        </Row>
                                    )}
                                    <Row className={`${UpdateStyle["submit-container"]}`}>
                                        <Form.Item className={`${UpdateStyle["btn-control"]} ${UpdateStyle["button-submit-form"]}`}>
                                            <Space>
                                                {showDuplicateEmailPage ?
                                                    <Button
                                                        className={`${UpdateStyle["form-button"]}`}
                                                        disabled={duplicateEmailValidationError ||
                                                            duplicateEmailMatchValidationError ||
                                                            duplicateEmailNewEmail.length === 0 ||
                                                            duplicateEmailConfirmEmail.length === 0}
                                                        onClick={() => handleDuplicateEmailFinish()}
                                                    >
                                                        Next
                                                    </Button>
                                                    :
                                                    <Button
                                                        className={`${UpdateStyle["form-button"]}`}
                                                        disabled={duplicateUsernameValidationError ||
                                                            duplicateUsernameMatchValidationError ||
                                                            duplicateUsernameNewUsername.length === 0 ||
                                                            duplicateUsernameConfirmUsername.length === 0}
                                                        onClick={() => handleDuplicateUsernameFinish()}
                                                    >
                                                        Next
                                                    </Button>
                                                }
                                            </Space>
                                        </Form.Item>
                                    </Row>
                                </Card>
                            </Row>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default Update
