import React from 'react'
import PropTypes from 'prop-types'

const DeleteIcon = ({ width = 22, height = 22 }) => {
  return (
    <svg
      height={height}
      preserveAspectRatio='xMidYMid meet'
      version='1.0'
      viewBox='0 0 46.5 46.5'
      width={width}
      zoomAndPan='magnify'
    >
      <defs>
        <clipPath id='id1'>
          <path
            clipRule='nonzero'
            d='M 8 10 L 38 10 L 38 46.003906 L 8 46.003906 Z M 8 10 '
          />
        </clipPath>
        <clipPath id='id2'>
          <path
            clipRule='nonzero'
            d='M 6.335938 0.0703125 L 40.085938 0.0703125 L 40.085938 8.5 L 6.335938 8.5 Z M 6.335938 0.0703125 '
          />
        </clipPath>
      </defs>
      <g clipPath='url(#id1)'>
        <path
          d='M 11.339844 42.671875 L 11.339844 12.597656 L 35.09375 12.597656 L 35.09375 42.671875 C 35.09375 43.359375 34.53125 43.921875 33.839844 43.921875 L 12.585938 43.921875 C 11.902344 43.921875 11.339844 43.359375 11.339844 42.671875 Z M 33.839844 46.429688 C 35.90625 46.429688 37.59375 44.738281 37.59375 42.671875 L 37.59375 10.097656 L 8.835938 10.097656 L 8.835938 42.671875 C 8.835938 44.738281 10.523438 46.429688 12.585938 46.429688 L 33.839844 46.429688 '
          fill='rgb(100%, 100%, 100%)'
          fillOpacity='1'
          fillRule='nonzero'
        />
      </g>
      <g clipPath='url(#id2)'>
        <path
          d='M 19.464844 2.578125 L 26.964844 2.578125 L 26.964844 5.082031 L 19.464844 5.082031 Z M 29.464844 0.0703125 L 16.964844 0.0703125 L 16.964844 5.710938 L 6.335938 5.710938 L 6.335938 8.214844 L 40.09375 8.214844 L 40.09375 5.710938 L 29.464844 5.710938 L 29.464844 0.0703125 '
          fill='rgb(100%, 100%, 100%)'
          fillOpacity='1'
          fillRule='nonzero'
        />
      </g>
      <path
        d='M 13.839844 15.730469 L 16.335938 15.730469 L 16.335938 40.164062 L 13.839844 40.164062 L 13.839844 15.730469 '
        fill='rgb(100%, 100%, 100%)'
        fillOpacity='1'
        fillRule='nonzero'
      />
      <path
        d='M 21.964844 15.730469 L 24.464844 15.730469 L 24.464844 40.164062 L 21.964844 40.164062 L 21.964844 15.730469 '
        fill='rgb(100%, 100%, 100%)'
        fillOpacity='1'
        fillRule='nonzero'
      />
      <path
        d='M 30.089844 15.730469 L 32.59375 15.730469 L 32.59375 40.164062 L 30.089844 40.164062 L 30.089844 15.730469 '
        fill='rgb(100%, 100%, 100%)'
        fillOpacity='1'
        fillRule='nonzero'
      />
    </svg>
  )
}

DeleteIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
}

export default DeleteIcon