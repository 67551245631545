import React, { useState, lazy, Suspense } from 'react'
import { MenuOutlined, PhoneOutlined } from '@ant-design/icons'
import { Button, Col, Layout, Row } from 'antd'

const DrawerMenu = lazy(() => import("./DrawerMenu/DrawerMenu.js"));

const Header = () => {
  const [hasLoadedDrawer, setHasLoadedDrawer] = useState(false)
  const [showDrawer, setShowDrawer] = useState(false)
  const [selectedMenu, setSelectedMenu] = useState()

  //Drawer controls
  const handleShowDrawer = event => {
    if(!hasLoadedDrawer){
      setHasLoadedDrawer(true);
    }
    setShowDrawer(true);
  };
  const onCloseDrawer = () => {
    setShowDrawer(false)
  }
  const handleMenuClick = e => {
    setSelectedMenu(e.key)
    setShowDrawer(false)
  }

  return (
    <div className="accessibility">
      {/* <Layout.Header className="alert-nav">
        <Row>
          <Col className="margin-auto mi-header">
            <img alt="mi auto insurance CURE" className="logo" src="https://www.cure.com/images/mi.png" />
            <Title className="alert-title" level={3}>
              GREAT LAKES, GREAT INSURANCE - NOW IN MICHIGAN!
            </Title>
            <img alt="mi CURE" className="alert-nav-img" src="https://www.cure.com/images/mi_underline.png" />
          </Col>
        </Row>
      </Layout.Header> */}
      <Layout.Header className="menu-nav">
        <Row>
          <Col xl={3} lg={4} md={5} sm={2} xs={2}>
            <div className="logo-holder">
              <a href="/">
                <img alt="CURE auto insurance logo" src="https://assets.cure.com/img/main-site/cure-logo-resized.webp" />
              </a>
            </div>
          </Col>
          <Col xl={10} lg={8} md={11} sm={12} xs={10} />
          <Col xl={11} lg={12} md={8} sm={10} xs={12}>
            <div className="expanol-link-container">
              <a
                className="rsa"
                href="tel:+18005352873"
                style={{
                  marginRight: 20,
                  color: '#fff',
                  verticalAlign: 'middle',
                  fontSize: '15px'
                }}
              >
                <PhoneOutlined className="phone" />
                <span className='contactNumber'>800-535-CURE (2873)</span>
              </a>
              <Button className="menu-icon" icon={<MenuOutlined />} onClick={handleShowDrawer} size="large" />
            </div>
          </Col>
          <Suspense fallback={<div></div>}>
            {
              hasLoadedDrawer ? 
              <DrawerMenu 
                open = {showDrawer}
                onCancel = {() => onCloseDrawer()}
                handleMenuClick = {() => handleMenuClick()}
                selectedMenu = {selectedMenu}
              /> : 
              null
            }
          </Suspense>
        </Row>
      </Layout.Header>
    </div>
  )
}

export default Header
