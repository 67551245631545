import { Button, Col, Row } from 'antd'
import PropTypes from 'prop-types'

import ButtonStyle from '../../styles/ButtonCell.module.css'

const ButtonCell = ({ link, logoType, mainText, selfOpen = false, subText, onClick }) => {
  return (
    <div className={`${ButtonStyle["ButtonCell"]}`}>
      <Row>
        <Col className="text-center">
          {logoType === 'file_claim' && (
            <Button
              className={`${ButtonStyle["file_claim"]}`}
              {...(link && {
                href: link,
                rel: 'noopener noreferrer'
              })}
              {...(onClick && { onClick })}
            >
              <img alt="file_claim" />
            </Button>
          )}
          {logoType === 'get_id_card' && (
            <Button
              className={`${ButtonStyle["get_id_card"]}`}
              {...(link && {
                href: link,
                rel: 'noopener noreferrer'
              })}
              {...(onClick && { onClick })}
            >
              <img alt="get_id_card" />
            </Button>
          )}
          {logoType === 'make_payment' && (
            <Button
              className={`${ButtonStyle["make_payment"]}`}
              {...(link && {
                href: link,
                rel: 'noopener noreferrer'
              })}
              {...(onClick && { onClick })}
            >
              <img alt="make_payment" />
            </Button>
          )}
          {logoType === 'manage_policy' && (
            <Button
              className={`${ButtonStyle["manage_policy"]}`}
              {...(link && {
                href: link,
                rel: 'noopener noreferrer'
              })}
              {...(onClick && { onClick })}
            >
              <img alt="manage_policy" />
            </Button>
          )}
          {logoType === 'create_account' && (
            <Button
              className={`${ButtonStyle["create_account"]}`}
              {...(link && {
                href: link,
                rel: 'noopener noreferrer'
              })}
            >
              <img alt="create_account" />
            </Button>
          )}
        </Col>
      </Row>
      <Row>
        <Col className={`${ButtonStyle["button-text"]} text-center`}>
          <a
            href={link}
            {...(!selfOpen && {
              rel: 'noopener noreferrer'
            })}
            {...(onClick && { onClick })}
          >
            <span>
              {mainText}
              <br />
              {subText}
            </span>
          </a>
        </Col>
      </Row>
    </div>
  )
}

ButtonCell.propTypes = {
  selfOpen: PropTypes.bool,
  link: PropTypes.string,
  logoType: PropTypes.string,
  mainText: PropTypes.string,
  onClick: PropTypes.func,
  subText: PropTypes.string
}

export default ButtonCell
