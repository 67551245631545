import React from 'react'
import { Col, Row } from 'antd'

const copyRightStyle = {
  margin: 'auto',
  fontSize: '14px',
  color: 'white',
  textAlign: 'center',
  marginRight: '0px !important',
  fontFamily: 'GlacialInDifference !important'
}

const Footers = () => {
  return (
    <footer className="global-footer">
      <Row className="desktop-footer">
        <Col
          lg={{ span: 7, offset: 1 }}
          md={{ span: 5, offset: 1 }}
          sm={{ span: 5, offset: 1 }}
          style={{ display: 'flex', margin: 'auto', marginTop: 3 }}
          xl={{ span: 8, offset: 0 }}
          xs={{ span: 8 }}
        >
          <span style={copyRightStyle}>
            Copyright© {new Date().getFullYear()} CURE (Citizens United Reciprocal Exchange). All rights reserved.
          </span>
        </Col>
        <Col
          lg={{ span: 3, offset: 1 }}
          md={{ span: 3, offset: 1 }}
          sm={{ span: 3, offset: 2 }}
          style={{ display: 'flex', margin: 'auto' }}
          xl={{ span: 3, offset: 0 }}
          xs={{ span: 5 }}
        >
          <Row className="text-reset" style={{ margin: 'auto' }}>
            <ul style={{ textAlign: "center", fontSize: '14px' }}>
              <li>Customer Service</li>
              <li className='desktop'>800-535-2873</li>
              <li className='mobile'>
                <a href="tel:+18005352873">
                  800-535-2873
                </a>
              </li>
            </ul>
          </Row>
        </Col>
        <Col
          lg={{ span: 4, offset: 1 }}
          md={{ span: 4, offset: 1 }}
          sm={{ span: 3, offset: 1 }}
          style={{ display: 'flex', margin: 'auto' }}
          xl={{ span: 3, offset: 0 }}
          xs={{ span: 5 }}
        >
          <Row className="text-reset" style={{ margin: 'auto' }}>
            <ul style={{ textAlign: "center", fontSize: '14px' }}>
              <li>Roadside Assistance</li>
              <li className='desktop'>866-522-1991</li>
              <li className='mobile'>
                <a href="tel:+18665221991">
                  866-522-1991
                </a>
              </li>
            </ul>
          </Row>
        </Col>
        <Col
          lg={{ span: 3, offset: 1 }}
          md={{ span: 3, offset: 1 }}
          sm={{ span: 3, offset: 2 }}
          style={{ display: 'flex', margin: 'auto' }}
          xl={{ span: 3, offset: 0 }}
          xs={{ span: 5 }}
        >
          <Row className="text-reset" style={{ margin: 'auto' }}>
            <ul style={{ textAlign: "center", fontSize: '14px' }}>
              <li>Report Claim</li>
              <li className='desktop'>800-535-2873</li>
              <li className='mobile'>
                <a href="tel:+18005352873">
                  800-535-2873
                </a>
              </li>
            </ul>
          </Row>
        </Col>
        <Col
          lg={{ span: 6, offset: 1 }}
          md={{ span: 4, offset: 1 }}
          sm={{ span: 4, offset: 1 }}
          style={{ display: 'flex', margin: 'auto' }}
          xl={{ span: 7, offset: 0 }}
          xs={{ span: 12 }}
        >
          <Row className="text-reset address">
            <ul style={{ textAlign: "center", fontSize: '14px' }}>
              <li>214 Carnegie Center, Suite 301</li>
              <li>Princeton, NJ 08540</li>
            </ul>
          </Row>
        </Col>
      </Row>
    </footer>
  )
}

export default Footers
