import React, { useCallback, useEffect, useState } from 'react'
import { UserOutlined, EnvironmentOutlined, CloseOutlined, LoadingOutlined } from '@ant-design/icons'
import { Row, Form, Input, Button, Card, Typography, Spin } from 'antd'
import InputMask from 'react-input-mask'
import PropTypes from 'prop-types'
import { useApiData } from '../context/apiContext'

import MyAccountStyle from '../../styles/MyAccount.module.css'

const { Text } = Typography
const LOGIN_ERROR = 'Unable to verify using the information provided.'

const antIcon = <LoadingOutlined spin style={{ fontSize: 24 }} />

const QSLogin = ({
  quickServiceClass,
  closeQSModal,
  message,
  showSpinner,
  setShowSpinner,
  selectedQS,
  handleSubmit
}) => {
  const [zipCode, setZipCode] = useState('')
  const [ssnNumber, setSSNNumber] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [loginMessage, setLoginMessage] = useState('')
  const [websiteMaintenanceMessage, setWebsiteMaintenanceMessage] = useState(null)
  const [websiteMaintenanceStatus, setWebsiteMaintenanceStatus] = useState(false)
  const { apiData1, apiData2 } = useApiData();

  useEffect(() => {
    if (apiData1 && apiData2) {
      function fetchWebsiteMaintainence() {
        var websiteStatusResponse = apiData1
        var websiteMessageResponse = apiData2

        const status = websiteStatusResponse?.data;
        if (status?.Result == "Active" && status?.Pages.includes("MyAccountLogin")) {
          setWebsiteMaintenanceStatus(!status.AllowAccessPages.includes("MyAccountLogin"));
          setWebsiteMaintenanceMessage(websiteMessageResponse?.data?.Result);
        } else {
          setWebsiteMaintenanceStatus(false);
          setWebsiteMaintenanceMessage(null);
        }
      }
      fetchWebsiteMaintainence()
    }
  }, [apiData1, apiData2]);

  useEffect(() => {
    if (message) {
      if (message === 'Not Found') {
        setErrorMessage(LOGIN_ERROR)
        setLoginMessage('')
      } else {
        setLoginMessage(message)
        setErrorMessage('')
      }
    }
  }, [message])

  const resetState = useCallback(() => {
    setZipCode('')
    setSSNNumber('')
    setErrorMessage('')
    setLoginMessage('')
    setShowSpinner(false)
  }, [setShowSpinner])

  useEffect(() => {
    if (selectedQS) {
      resetState()
    }
  }, [resetState, selectedQS])

  const handleClick = () => {
    setErrorMessage('')
    setLoginMessage('')
    setShowSpinner(false)
    const response = validateForm()
    if (!response) {
      handleSubmit(ssnNumber, zipCode)
    }
  }

  const handleZipCodeChange = event => {
    const value = event.target.value.replace(/\D/g, '')
    setZipCode(value)
  }

  const handleSSNChange = event => {
    const value = event.target.value.replace(/\D/g, '')
    setSSNNumber(value)
  }

  const validateForm = () => {
    setLoginMessage('')
    setErrorMessage('')
    let validationResponse = null
    if (ssnNumber.length === 0 || ssnNumber.length !== 10) {
      validationResponse = LOGIN_ERROR
    }
    setErrorMessage(validationResponse)
    if (!validationResponse) {
      if (zipCode.length === 0) {
        validationResponse = LOGIN_ERROR
      }
      if (zipCode.length > 0 && zipCode.length !== 5) {
        validationResponse = LOGIN_ERROR
      }
    }
    setErrorMessage(validationResponse)
    return validationResponse
  }

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      handleClick()
    }
  }

  return (
    <Card
      bordered={false}
      className={quickServiceClass}
      extra={
        <a
          className="signin-close"
          onClick={() => {
            resetState()
            closeQSModal()
          }}
        >
          <CloseOutlined />
        </a>
      }
      title="Quick Service Access"
    >
      {selectedQS && <Text className="sub-title selected-qs">{selectedQS}</Text>}
      {websiteMaintenanceMessage && (
        <Row>
          <div className="blue-message float-left blue-message-bold" style={{ marginTop: 12, marginBottom: 18 }}>
            {websiteMaintenanceMessage}
          </div>
        </Row>
      )}
      {!websiteMaintenanceStatus && <Row className={`${MyAccountStyle["create-account-text"]}`} />}
      <Form.Item>
        <span className="ant-input-affix-wrapper input-text">
          <span class="ant-input-prefix">
            <UserOutlined />
          </span>
          <InputMask
            autoComplete="off"
            className="ant-input"
            disabled={websiteMaintenanceStatus}
            mask="(999) 999-9999"
            onChange={handleSSNChange}
            onKeyDown={handleKeyDown}
            placeholder="Phone Number"
            value={ssnNumber}
          />
        </span>
      </Form.Item>
      <Form.Item>
        <Input
          autoComplete="off"
          className="input-text"
          disabled={websiteMaintenanceStatus}
          maxLength={5}
          onChange={handleZipCodeChange}
          onKeyDown={handleKeyDown}
          placeholder="Zip Code"
          prefix={<EnvironmentOutlined />}
          value={zipCode}
        />
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        {loginMessage !== 'Success' && !showSpinner && <div className="success-message">{loginMessage}</div>}
        {showSpinner && <div className="blue-message float-left">Please wait ...</div>}
        {showSpinner && <Spin indicator={antIcon} />}
      </Form.Item>
      <Form.Item className={`${MyAccountStyle["btn-control"]}`}>
        <Button className={`login ${MyAccountStyle["login-form-button"]}`} disabled={websiteMaintenanceStatus} onClick={handleClick}>
          Continue
        </Button>
      </Form.Item>
    </Card>
  )
}

QSLogin.propTypes = {
  message: PropTypes.string,
  quickServiceClass: PropTypes.string,
  closeQSModal: PropTypes.func,
  handleSubmit: PropTypes.func,
  selectedQS: PropTypes.string,
  showSpinner: PropTypes.bool,
  setShowSpinner: PropTypes.func
}

export default QSLogin
