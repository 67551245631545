import React, { useEffect, useState, lazy, Suspense } from 'react'
import CreditCardOutlined from '@ant-design/icons/CreditCardOutlined'
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined'
import MenuOutlined from '@ant-design/icons/MenuOutlined'
import PhoneOutlined from '@ant-design/icons/PhoneOutlined'
import { Button, Col, Layout, Menu, Row, Typography, Skeleton } from 'antd'

import PropTypes from 'prop-types'
import { getDomain } from '../utils'
import { useWindowSize } from '../../src/utils'
import { useApiData } from '../context/apiContext'

const FeedbackModal = lazy(() => import("./FeedbackModal/FeedbackModal.js"));
const DrawerMenu = lazy(() => import("./DrawerMenu/DrawerMenu.js"));

const { Link } = Typography

const Header = (props) => {
  const [hasLoadedDrawer, setHasLoadedDrawer] = useState(false)
  const [showDrawer, setShowDrawer] = useState(false)
  const [selectedMenu, setSelectedMenu] = useState()

  const [hasLoadedFeedback, setHasLoadedFeedback] = useState(false)
  const [showFeedbackModal, setShowFeedbackModal] = useState(false)

  const [websiteMaintenanceMessage, setWebsiteMaintenanceMessage] = useState(null)
  const [websiteMaintenanceStatus, setWebsiteMaintenanceStatus] = useState(false)
  const [isMaintainenceLoading, setIsMaintainenceLoading] = useState(false)
  const { apiData1, apiData2 } = useApiData();

  useEffect(() => {
    if (apiData1 && apiData2) {
      function fetchWebsiteMaintainence() {
        var websiteStatusResponse = apiData1;
        var websiteMessageResponse = apiData2;
        var isMyAcc = window.location.pathname.includes('my-account');

        const status = websiteStatusResponse?.data;
        if (status?.Result == "Active" && status?.Pages.includes(isMyAcc ? "MyAccountLogin" : "CurePages")) {
          setWebsiteMaintenanceStatus(isMyAcc ? !status.AllowAccessPages.includes("MyAccountLogin") : true);
          setWebsiteMaintenanceMessage(websiteMessageResponse?.data?.Result);
        } else {
          setWebsiteMaintenanceStatus(false);
          setWebsiteMaintenanceMessage(null);
        }
        
        setIsMaintainenceLoading(true)
        setIsLoading(false)
      }
      fetchWebsiteMaintainence()
    }
  }, [apiData1, apiData2]);

  //Drawer controls
  const handleShowDrawer = event => {
    if(!hasLoadedDrawer){
      setHasLoadedDrawer(true);
    }
    setShowDrawer(true);
  };
  const onCloseDrawer = () => {
    setShowDrawer(false)
  }
  const handleMenuClick = e => {
    setSelectedMenu(e.key)
    setShowDrawer(false)
  }

  //Feedback modal controls
  const handleShowFeedback = event => {
    if(!hasLoadedFeedback){
      setHasLoadedFeedback(true);
    }
    setShowFeedbackModal(true);
  };
  const onCloseFeedback = () => {
    setShowFeedbackModal(false);
  };
  
  const [isLoading, setIsLoading] = useState(true);
  const windowSize = useWindowSize()

  if (isLoading) {
    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: (windowSize.width < 1026 ? 112 : 64), backgroundColor: '#1d4c80' }}>
          <Skeleton.Button
            active
            style={{ width: 200, backgroundColor: 'rgb(24 84 141)' }}
            shape='round'
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: (windowSize.width < 1026 ? 112 : 64), backgroundColor: '#376998' }}>
          <Skeleton.Button
            active
            style={{ width: 200, backgroundColor: 'rgb(24 84 141)' }}
            shape='round'
          />
        </div>
      </>
    )
  }

  return (
    <>
      {isMaintainenceLoading && (
        <>
          {websiteMaintenanceMessage && (
            <Layout.Header className="alert-nav">
              <Row>
                <Col className="margin-auto">
                  <section className="alert-message">
                    <div className="image-icon">
                      <InfoCircleOutlined />
                    </div>
                    <p className="title">{websiteMaintenanceMessage}</p>
                  </section>
                </Col>
                <Button className="feedback" onClick={handleShowFeedback}>
                  SHARE FEEDBACK
                </Button>
              </Row>
            </Layout.Header>
          )}
          {!websiteMaintenanceStatus && (
            <Layout.Header className="alert-nav">
              <Row>
                <Col className="margin-auto">
                  <a onClick={() => window.open('/download-mobile-app', '_self', 'noopener,noreferrer')}>
                    <img alt="mi auto insurance CURE" className="logo" src="https://staging.cure.com/images/bannerPhoneIcon.png" style={{ paddingBottom: '10px', width: '30px' }} />
                    <span className='alert-title' style={{ marginLeft: '15px', fontSize: '22px' }}>
                      CURE App. Everything at your fingertips. Download Now.
                    </span>
                    <img alt="mi CURE" className="alert-nav-img" src="https://assets.cure.com/img/main-site/webp/mi_underline.webp" />
                  </a>
                </Col>
                {/* <Button className="feedback" onClick={() => setShowFeedbackModal(true)}>
                  SHARE FEEDBACK
                </Button> */}
              </Row>
            </Layout.Header>
          )}
          <Layout.Header className="menu-nav">
            <Row>
              <Col lg={4} md={5} sm={2} xl={3} xs={2}>
                <div className="logo-holder">
                  <a href="/">
                    <img 
                      alt="CURE auto insurance logo" 
                      src="https://assets.cure.com/img/main-site/cure-logo-resized.webp"
                      width={189} 
                    />
                  </a>
                </div>
              </Col>
              <Col lg={8} md={5} sm={2} xl={10} xs={2}>
                <Menu className="menu-container" mode="horizontal">
                  <Menu.Item key="1">
                    <a href="/claims">CLAIMS</a>
                  </Menu.Item>
                  <Menu.Item key="roadsideassistance" className="roadside-assistance-menu">
                    <a href="/roadside">ROADSIDE</a>
                  </Menu.Item>
                  <Menu.Item key="7">
                    <a
                      href={'https://' + getDomain('payments')}
                      rel="noopener noreferrer"
                      target="_self"
                      onClick={() => props.setLoading(true)}
                    >
                      PAY ONLINE
                    </a>
                  </Menu.Item>
                </Menu>
              </Col>
              <Col lg={12} md={5} sm={2} xl={11} xs={2}>
                <div className="expanol-link-container">
                  <Link
                    href="tel:+18005352873"
                    style={{
                      marginRight: 20,
                      color: '#fff',
                      verticalAlign: 'middle'
                    }}
                  >
                    <PhoneOutlined className="phone" />
                    800-535-CURE (2873)
                  </Link>
                  <Button
                    className="start-quote"
                    href={'https://' + getDomain('quote') + '/default.aspx'}
                    rel="noopener noreferrer"
                    style={{
                      marginRight: 20,
                      background: '#ff8000',
                      border: 'none',
                      color: '#fff'
                    }}
                    target="_self"
                  >
                    START QUOTE
                  </Button>
                  <Button
                    className="sign-in"
                    href="/my-account"
                    rel="noopener noreferrer"
                    style={{
                      marginRight: 20,
                      background: '#ff8000',
                      border: 'none',
                      color: '#fff'
                    }}
                  >
                    LOG IN
                  </Button>
                </div>
              </Col>
            </Row>
          </Layout.Header>
          <Layout.Header className="menu-nav-mobile">
            <Row>
              <Col lg={4} md={5} sm={5} xl={3} xs={10}>
                <div className="logo-holder">
                  <a href="/">
                    <img alt="CURE auto insurance logo" src="https://assets.cure.com/img/main-site/cure-logo-resized.webp" />
                  </a>
                </div>
              </Col>
              <Col className="space-column" lg={8} md={8} sm={9} xl={10} xs={9} />
              <Col lg={12} md={11} sm={10} xl={10} xs={14}>
                <div className="expanol-link-container">
                  <Button
                    className="start-quote"
                    href={'https://' + getDomain('quote') + '/default.aspx'}
                    rel="noopener noreferrer"
                    target="_self"
                  >
                    START QUOTE
                  </Button>
                  <Button className="menu-icon" icon={<MenuOutlined />} onClick={handleShowDrawer} size="large" />
                </div>
                <Suspense fallback={<div></div>}>
                  {
                    hasLoadedDrawer ? 
                    <DrawerMenu 
                      open = {showDrawer}
                      onCancel = {() => onCloseDrawer()}
                      handleMenuClick = {() => handleMenuClick()}
                      selectedMenu = {selectedMenu}
                    /> : 
                    null
                  }
                </Suspense>
              </Col>
            </Row>
          </Layout.Header>
          <Layout.Header className="secondary-nav">
            <Row style={{ display: 'grid', gridColumnGap: 18 }}>
              <Col style={{ gridColumn: '1' }}>
                <Link className="link-text" href="tel:+18005352873">
                  <PhoneOutlined className="phone" />
                  Contact Us
                </Link>
              </Col>
              <Col className="center" style={{ gridColumn: '2' }}>
                <Link className="link-text" href="tel:+18665221991">
                  <PhoneOutlined className="phone" />
                  <span className="rsa-mobile-text">Roadside 24/7</span>
                </Link>
              </Col>
              <Col className="right" style={{ gridColumn: '3' }}>
                <Link
                  className="link-text"
                  href={'https://' + getDomain('payments')}
                  rel="noopener noreferrer"
                  style={{ marginLeft: 6 }}
                  target="_self"
                  onClick={() => props.setLoading(true)}
                >
                  <CreditCardOutlined className="credit-card" />
                  Pay Online
                </Link>
              </Col>
            </Row>
          </Layout.Header>
          <Suspense fallback={<div></div>}>
            {
              hasLoadedFeedback ? 
              <FeedbackModal 
              open = {showFeedbackModal}
              onCancel = {() => onCloseFeedback()}
              /> : 
              null
            }
          </Suspense>
        </>
      )}
    </>
  )
}

Header.propTypes = {
  hideGlobalHeader: PropTypes.bool
}

Header.defaultProps = {
  hideGlobalHeader: true
}

export default Header
