import Footer from '../../src/components/Footer'
import Header from '../../src/components/Header'
import Update from '../../src/components/Update'

export default function Index() {
  return (
    <>
      <Header />
      <div className="cure-inner-container">
        <Update />
      </div>
      <Footer />
    </>
  )
}
