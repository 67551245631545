import React from 'react'
import { Button, Col, Row } from 'antd'

import SupportStyle from '../../styles/SupportService.module.css'

const SupportService = () => {
  const handleClick = () => {
    window.location = '/why-cure'
  }

  return (
    <Row className={`${SupportStyle["fixed-support-box"]}`}>
      <Col className={`${SupportStyle["SupportBox"]}`}>
        <Row>
          <Col className={`${SupportStyle["text-center"]}`}>
            <h2 className={`${SupportStyle["cursive"]}`}>
              Show your support...
            </h2>
          </Col>
        </Row>
        <Row>
          <Col className={`${SupportStyle["text-center"]} ${SupportStyle["sub-text"]}`}>
            <span>
              At CURE, we don’t need reform to do what’s right. We have never used education, occupation or credit score
              to determine car insurance rates and advocate against others who do. Why? Because CURE believes everyone
              deserves affordable car insurance based primarily on their driving record.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: 5 }}>
          <Col className={`${SupportStyle["button-container"]}`}>
            <Button onClick={handleClick} style={{ fontSize: 18 }} type="primary">
              LEARN MORE
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default SupportService
