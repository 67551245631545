import { Row } from 'antd'
import PropTypes from 'prop-types'

const EmptyHeaderHero = ({ className = "", title, subTitle, leftAlign, isTitleBold, minHeight }) => {
  const headerClassName = isTitleBold ? 'bold-title' : 'title'
  const cls = minHeight ? `${headerClassName} min-height-90` : headerClassName
  const updatedClass = leftAlign ? `${cls} left-head-align` : cls

  return (
    <header className={`empty-header-hero${(className == "" ? "" : ` ${className}`)}`}>
      <Row>
        <h2 className={updatedClass}>
          {title}
          {subTitle}
        </h2>
      </Row>
      <hr />
    </header>
  )
}

EmptyHeaderHero.propTypes = {
  className: PropTypes.string,
  isTitleBold: PropTypes.bool,
  leftAlign: PropTypes.bool,
  minHeight: PropTypes.bool,
  subTitle: PropTypes.object,
  title: PropTypes.string
}

export default EmptyHeaderHero
