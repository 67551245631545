import { createContext, useContext, useState } from 'react';

const ApiDataContext = createContext();

export const ApiDataProvider = ({ children }) => {
  const [apiData1, setApiData1] = useState(null);
  const [apiData2, setApiData2] = useState(null);

  const websiteStatus = (newData) => {
    setApiData1(newData);
  };

  const websiteMessage = (newData) => {
    setApiData2(newData);
  };

  return (
    <ApiDataContext.Provider value={{ apiData1, apiData2, websiteStatus, websiteMessage }}>
      {children}
    </ApiDataContext.Provider>
  );
};

export const useApiData = () => {
  return useContext(ApiDataContext);
};
