import { getAPIEndpoint } from '../utils/domainUtil'
const REACT_SP_API_URL = `${getAPIEndpoint()}/api/service-portal`
const REACT_APP_API_URL = `${getAPIEndpoint()}/api/app`

export const loginEndpoint = {
  verify: {
    method: 'POST',
    url: `${getAPIEndpoint()}/api/my-account/VerifyLogin`
  },
  checkSelfService: {
    method: 'POST',
    url: `${getAPIEndpoint()}/api/my-account/CheckSelfService`
  },
  retrivePolicyUser: {
    method: 'POST',
    url: `${getAPIEndpoint()}/api/my-account/RetrievePolicyUser`
  },
  checkGWAccount: {
    method: 'POST',
    url: `${getAPIEndpoint()}/api/my-account/CheckGWAccount`
  },
  getZip: {
    method: 'POST',
    url: `${getAPIEndpoint()}/api/my-account/GetZip`
  },
  getSecurityQuestions: {
    method: 'POST',
    url: `${getAPIEndpoint()}/api/my-account/GetSecurityQuestions`
  },
  isUsernameRegistered: {
    method: 'POST',
    url: `${getAPIEndpoint()}/api/my-account/IsUsernameRegistered`
  },
  checkSecurityQuestions: {
    method: 'POST',
    url: `${getAPIEndpoint()}/api/my-account/CheckSecurityQuestions`
  },
  checkPassword: {
    method: 'POST',
    url: `${getAPIEndpoint()}/api/my-account/CheckPassword`
  },
  updatePassword: {
    method: 'POST',
    url: `${getAPIEndpoint()}/api/my-account/UpdatePassword`
  },
  isEmailRegistered: {
    method: 'POST',
    url: `${getAPIEndpoint()}/api/my-account/IsEmailRegistered`
  },
  updateEmail: {
    method: 'POST',
    url: `${getAPIEndpoint()}/api/my-account/UpdateEmail`
  },
  registerAccount: {
    method: 'POST',
    url: `${getAPIEndpoint()}/api/my-account/RegisterAccount`
  },
  feedback: {
    method: 'POST',
    url: `${getAPIEndpoint()}/api/main/ProcessFeedback`
  },
  insertEmailUpdate: {
    method: 'POST',
    url: `${getAPIEndpoint()}/api/main/InsertEmailUpdate`
  },
  getPolicyFromEmailUpdateToken: {
    method: 'POST',
    url: `${getAPIEndpoint()}/api/main/GetPolicyFromEmailUpdateToken`
  },
  getUseGenesysWebMessaging: {
    method: 'GET',
    url: `${getAPIEndpoint()}/api/main/GetUseGenesysWebMessaging`
  },
  initiateAgentlessConversation: {
    method: 'POST',
    url: `${getAPIEndpoint()}/api/main/InitiateAgentlessConversation`
  },
  resetPassword: {
    method: 'POST',
    url: `${getAPIEndpoint()}/api/my-account/SendTempPassword`
  }
}

export const policyChangeEndpoint = {
  checkCodes: {
    method: "POST",
    url: `${REACT_SP_API_URL}/HandleChangeRequestCheckCodes`,
  },

  twilioRegistration: {
    method: "POST",
    url: `${REACT_APP_API_URL}/TwilioRegistration`,
  },
};

export const sendEmailEndpoint = {
  expressPolicyChange: {
    method: 'POST',
    url: `${getAPIEndpoint()}/api/main/SendExpressEmail`
  },
  PIPDocumentUpload: {
    method: 'POST',
    url: `${getAPIEndpoint()}/api/main/EmailAttachment`
    // url: `http://34.133.222.14:5001/campaign/emailAttachmentsWithParam`
  },
  claimFnol: {
    method: 'POST',
    url: `${getAPIEndpoint()}/api/main/SendFNOLEmail`
  }
}

export const sendFNOLDataEndpoint = {
  sendFNOLData: {
    method: 'POST',
    url: `${getAPIEndpoint()}/api/main/SendFNOLData`
  }
}

export const websiteMaintenanceEndpoint = {
  status: {
    method: 'POST',
    url: `${getAPIEndpoint()}/api/main/CheckWebsiteMaintenance`
  },
  message: {
    method: 'POST',
    url: `${getAPIEndpoint()}/api/main/GetWebsiteMaintenanceMessage`
  }
}

export const adminEmailEndpoint = {
  unsubscribe: email => ({
    method: 'POST',
    url: `${NEXT_PUBLIC_API_URL}/admin/unSubscribeCampaign/${email}`
  })
}
