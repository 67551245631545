export const Styles = {
  container: {
    position: 'fixed',
    border: '3px solid #323639',
    backgroundColor: 'white',
    color: 'black',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center',
    bottom: '10px',
    right: '10px',
    zIndex: '1000',
    maxWidth: '325px'
  },
  innerContainer: {
    backgroundColor: '#1468A6',
    color: 'white',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px',
    width: '100%',
    positon: 'relative',
    paddingRight: '0px'
  },
  cancelBtn: mobileApp => {
    return {
      position: 'absolute',
      color: 'white',
      right: '6px',
      top: '6px',
      cursor: 'pointer',
      marginRight: '5px',
      ...(mobileApp && {
        fontSize: 30,
        fontWeight: 'bold'
      })
    }
  },
  headerImg: {
    width: '50%',
    fontSize: '20px',
    marginBottom: '0px',
    fontWeight: 'bold',
    margin: 'auto'
  },
  upperSect: {
    padding: '15px',
    fontSize: '16px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '15px'
  },
  upperSectTxt: mobileApp => {
    return {
      marginBottom: '0px',
      ...(mobileApp && {
        fontSize: 20,
        fontWeight: 'bold'
      })
    }
  },
  inputPhone: mobileApp => {
    return {
      borderRadius: 7.5,
      padding: 5,
      ...(mobileApp && {
        paddingLeft: 16,
        width: '100%',
        height: 50,
        fontSize: 14
      })
    }
  },
  sendBtn: mobileApp => {
    return {
      backgroundColor: '#ff8000',
      color: 'white',
      borderRadius: 7.5,
      ...(mobileApp && {
        fontSize: 20,
        alignSelf:'center',
        width: '100%',
        height: 50,
        fontWeight: 'bold'
      })
    }
  },
  link: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '20px'
  }
}
